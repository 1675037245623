import '../../styles/adminCss/adminAddProducts.css'

import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link, useNavigate } from 'react-router-dom';
import { FcViewDetails } from "react-icons/fc";
function decryptLink(encryptedLink, secretKey) {
    const decipher = crypto.createDecipher('aes-256-cbc', secretKey);
    let decryptedLink = decipher.update(encryptedLink, 'hex', 'utf8');
    decryptedLink += decipher.final('utf8');
    return decryptedLink;
}



const noti = withReactContent(Swal);
const AddAdminProduct = () => {

    const [productos, setProductos] = useState([]);
    const [productoSeleccionado, setProductoSeleccionado] = useState(null);

    /* PAGINACION */
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Cambia esto según la cantidad de elementos por página que desees

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProducts = productos.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        cargarProductos();
    }, []);

    const cargarProductos = async () => {
        try {
            const response = await Axios.get('https://servermascoteh.onrender.com/productos');
            setProductos(response.data);
        } catch (error) {
            console.error('Error al cargar productos:', error);
        }
    };

    const agregarProducto = () => {
        Swal.fire({
            title: 'Agregar Producto',
            html: `
                <label for="nombre">Nombre:</label>
                <input id="nombre" class="swal2-input" placeholder="Nombre"><br>
                <label for="descripcion">Descripción:</label>
                <input id="descripcion" class="swal2-input" placeholder="Descripción"><br>
                <label for="precio">Precio:</label>
                <input id="precio" type="number" class="swal2-input" placeholder="Precio"><br>
                <label for="marca">Marca:</label>
                <input id="marca" class="swal2-input" placeholder="Marca"><br>
                <label for="tipo">Tipo:</label>
                <input id="tipo" class="swal2-input" placeholder="Tipo"><br>
                <label for="stock">Stock:</label>
                <input id="stock" type="number" class="swal2-input" placeholder="Stock"><br>
                <label for="imagen">Imagen:</label>
                <input id="imagen" type="file" class="swal2-file" accept="image/*" placeholder="Seleccionar Imagen">
            `,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                const nombre = document.getElementById('nombre').value;
                const descripcion = document.getElementById('descripcion').value;
                const precio = document.getElementById('precio').value;
                const marca = document.getElementById('marca').value;
                const tipo = document.getElementById('tipo').value;
                const stock = document.getElementById('stock').value;
                const imagenInput = document.getElementById('imagen');
                const imagenFile = imagenInput.files[0]; // Obtener el archivo de imagen seleccionado

                // Validar que los campos obligatorios no estén vacíos
                if (!nombre || !descripcion || !precio || !marca || !tipo || !stock || !imagenFile) {
                    Swal.showValidationMessage('Todos los campos son obligatorios');
                    return;
                }

                // Crear un objeto FormData para enviar los datos del producto, incluida la imagen
                const formData = new FormData();
                formData.append('nombre', nombre);
                formData.append('descripcion', descripcion);
                formData.append('precio', precio);
                formData.append('marca', marca);
                formData.append('tipo', tipo);
                formData.append('stock', stock);
                formData.append('imagen', imagenFile);

                // Realizar la petición POST al servidor con los datos del producto
                return Axios.post('https://servermascoteh.onrender.com/InsertProduct', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data' // Especificar el tipo de contenido como 'multipart/form-data' para subir archivos
                    }
                }).then(() => {
                    Swal.fire('Producto agregado', '', 'success');
                    cargarProductos();
                }).catch(error => {
                    console.error('Error al agregar producto:', error);
                    Swal.fire('Error', 'No se pudo agregar el producto', 'error');
                });
            }
        });
    };
    const editarProducto = (producto) => {
        setProductoSeleccionado(producto);
        Swal.fire({
            width: '800px', // Aumentamos el ancho del Swal
            title: 'Editar Producto',
            html: `
                <label for="nombre" class="swal2-label">Nombre:</label>
                <input id="nombre" value="${producto.nombre}" class="swal2-input" placeholder="Nombre"><br><br>
    
                <label for="descripcion" class="swal2-label">Descripción:</label>
                <textarea id="descripcion" class="swal2-textarea" placeholder="Descripción" style="width: 750px;">${producto.descripcion}</textarea><br><br>
    
                <label for="precio" class="swal2-label">Precio:</label>
                <input id="precio" value="${producto.precio}" type="number" class="swal2-input" placeholder="Precio"><br><br>
    
                <label for="marca" class="swal2-label">Marca:</label>
                <input id="marca" value="${producto.marca}" class="swal2-input" placeholder="Marca"><br><br>
    
                <label for="tipo" class="swal2-label">Tipo:</label>
                <input id="tipo" value="${producto.tipo}" class="swal2-input" placeholder="Tipo"><br><br>
    
                <label for="stock" class="swal2-label">Stock:</label>
                <input id="stock" value="${producto.stock}" type="number" class="swal2-input" placeholder="Stock"><br><br>
    
                <label for="imagen" class="swal2-label">Imagen:</label>
                <input id="imagen" type="file" class="swal2-file" accept="image/*" placeholder="Seleccionar Imagen"><br>
            `,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                const nombre = Swal.getPopup().querySelector('#nombre').value;
                const descripcion = Swal.getPopup().querySelector('#descripcion').value;
                const precio = Swal.getPopup().querySelector('#precio').value;
                const marca = Swal.getPopup().querySelector('#marca').value;
                const tipo = Swal.getPopup().querySelector('#tipo').value;
                const stock = Swal.getPopup().querySelector('#stock').value;
                const imagenInput = Swal.getPopup().querySelector('#imagen');
                const imagenFile = imagenInput.files[0]; // Obtener el archivo de imagen seleccionado

                const formData = new FormData();
                formData.append('nombre', nombre);
                formData.append('descripcion', descripcion);
                formData.append('precio', precio);
                formData.append('marca', marca);
                formData.append('tipo', tipo);
                formData.append('stock', stock);

                // Si se seleccionó una imagen, la añadimos al FormData
                if (imagenFile) {
                    formData.append('imagen', imagenFile);
                }

                return Axios.put(`https://servermascoteh.onrender.com/productosedit/${producto._id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(() => {
                    Swal.fire('Producto actualizado', '', 'success');
                    cargarProductos();
                }).catch(error => {
                    console.error('Error al actualizar producto:', error);
                    Swal.fire('Error', 'No se pudo actualizar el producto', 'error');
                });
            }
        });
    };



    const eliminarProducto = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará el producto permanentemente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                Axios.delete(`https://servermascoteh.onrender.com/productos/${id}`).then(() => {
                    cargarProductos();
                    Swal.fire(
                        'Producto eliminado',
                        'El producto ha sido eliminado correctamente.',
                        'success'
                    );
                }).catch(error => {
                    console.error('Error al eliminar producto:', error);
                    Swal.fire(
                        'Error',
                        'No se pudo eliminar el producto.',
                        'error'
                    );
                });
            }
        });
    };
    const showProduct = (producto) => {
        Swal.fire({
            width: '800px', // Aumentamos el ancho del Swal
            title: 'Detalles del Producto',
            html: `
                <div>
                    <p><strong>ID:</strong> ${producto._id}</p>
                    <p><strong>Nombre:</strong> ${producto.nombre}</p>
                    <p><strong>Descripción:</strong> ${producto.descripcion}</p>
                    <p><strong>Precio:</strong> ${producto.precio}</p>
                    <p><strong>Marca:</strong> ${producto.marca}</p>
                    <p><strong>Tipo:</strong> ${producto.tipo}</p>
                    <p><strong>Stock:</strong> ${producto.stock}</p>
                    <p><strong>Características:</strong> ${producto.caracteristicas}</p>
                    <p><strong>Descripción Detallada:</strong> ${producto.descripcion_detallada}</p>
                    <img src="${producto.imagen}" alt="Imagen del producto" style="max-width: 100%; height: auto;">
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cerrar',
            cancelButtonColor: '#d33'
        });
    };
    return (
        <section className="u-align-center u-clearfix u-white u-section-addP" id="sec-ad19">
            <div className="u-expanded-width u-table u-table-responsive u-table-1">
                <h2 style={{
                    color: "#fff",
                    textShadow: "0 0 10px #000, 0 0 20px #000, 0 0 30px #000, 0 0 40px #000, 0 0 50px #000, 0 0 60px #000, 0 0 70px #000, 0 0 80px #000",
                    fontFamily: "ADLaM Display",
                    textAlign: "center"
                }} className="">ADMINISTRACION DE PRODUCTOS</h2>
                <br />
                <button className="add-button" onClick={agregarProducto}>Agregar Producto</button>
                <br />
                <br />
                <div className='u-table-container'>
                    <table className="u-table-entity">
                        <thead className="u-palette-4-base u-table-header u-table-header-1">
                            <tr style={{ height: 21 }}>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Nombre
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Precio
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Marca
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Tipo
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Stock
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody className="u-table-body" style={{ backgroundColor: 'white' }}>
                            {currentProducts.map(producto => (
                                <tr key={producto._id} style={{ height: 76 }}>

                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{producto.nombre}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{producto.precio}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{producto.marca}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{producto.tipo}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{producto.stock}</td>

                                    <td style={{ display: "flex" }} className="u-border-1 u-border-grey-30 u-table-cell">
                                        <FcViewDetails onClick={() => showProduct(producto)} size={60}  />
                                        <button onClick={() => editarProducto(producto)} title="filter" class="filter">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="25" width="25">
                                                {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                                                <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
                                            </svg>
                                        </button>
                                        <button onClick={() => eliminarProducto(producto._id)} class="tooltip">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" height="25" width="25">
                                                <path fill="#6361D9" d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z" clip-rule="evenodd" fill-rule="evenodd"></path>
                                            </svg>
                                            <span class="tooltiptext">remove</span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Controles de paginación */}
                <div className="pagination">
                    {Array.from({ length: Math.ceil(productos.length / itemsPerPage) }, (_, i) => (
                        <button key={i} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
};
export default AddAdminProduct;