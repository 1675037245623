import React from 'react';
import '../styles/contacto.css'
import Swal from 'sweetalert2';
import Axios from 'axios';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
const UserWindow = () => {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [comentario, setComentario] = useState("");
  const [direccion, setDireccion] = useState("");
  const [calle, setCalle] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [codigo_postal, setCodigoPostal] = useState("");

  const navigation = useNavigate();
  const sendComentario = () => {
    // Obtener la ubicación del usuario

    // Enviar el comentario junto con la ubicación
    Axios.post("https://servermascoteh.onrender.com/InsertarContacto", {
      user: user,
      email: email,
      comentario: comentario,

    }).then(() => {
      Swal.fire({
        icon: 'info',
        title: `Comentario enviado con éxito`,
        text: `Bienvenido`,
        timer: 3000
      }).then(() => {
        navigation("/InicioSesion");
      });
    }).catch(error => {
      console.error("Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error,',
        text: error,
        timer: 3000
      });
    });

  }
  return (
    <section className="u-clearfix u-contact-1" id="carousel_109d">
      <div className="u-clearfix u-sheet u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-valign-middle-xs u-sheet-1">
        <div className="custom-expanded u-align-left u-container-align-left u-container-style u-group  u-group-1">
          <div style={{ backgroundColor: "#F1F1F1" }} className="u-container-layout u-valign-middle-lg u-valign-middle-xl u-valign-top-md u-valign-top-sm u-valign-top-xs u-container-layout-1">
            <h2 className="u-align-left u-text u-text-1">Contactanos</h2>
            <div className="u-align-left u-expanded-width u-form u-form-1">
              <form
                action="https://forms.nicepagesrv.com/v2/form/process"
                className="u-clearfix u-form-spacing-28 u-form-vertical u-inner-form"
                style={{ padding: 0 }}
                source="email"
                name="form"
              >
                <div className="u-form-group u-form-name">
                  <label
                    htmlFor="name-5a14"
                    className="u-form-control u-label"
                    style={{fontStyle:"italic"}}
                    
                  >
                    Nombre
                  </label>
                  <input
                    required
                    value={user} onChange={(event) => {
                      setUser(event.target.value);
                    }}
                    type="text"
                    placeholder="Introduzca su nombre"
                    id="name-5a14"
                    name="name"
                    className="u-border-2 u-border-black u-border-no-left u-border-no-right u-border-no-top u-input u-input-rectangle"

                  />
                </div>
                <div className="u-form-email u-form-group">
                  <label
                    htmlFor="email-5a14"
                    style={{fontStyle:"italic"}}
                  >
                    Email
                  </label>
                  <input
                    value={email} onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    type="email"
                    placeholder="Introduzca una dirección de correo electrónico válida"
                    id="email-5a14"
                    name="email"
                    className="u-border-2 u-border-black u-border-no-left u-border-no-right u-border-no-top u-input u-input-rectangle"
                    required=""
                  />
                </div>
                <div className="u-form-group u-form-message">
                  <label
                    htmlFor="message-5a14"
                    style={{fontStyle:"italic"}}
                  >
                    Comentarios
                  </label>
                  <textarea
                    value={comentario} onChange={(event) => {
                      setComentario(event.target.value);
                    }}
                    placeholder="Ingrese su mensaje"
                    rows={4}
                    cols={50}
                    id="message-5a14"
                    name="message"
                    className="u-border-2 u-border-black u-border-no-left u-border-no-right u-border-no-top u-input u-input-rectangle"
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="u-align-left u-form-group u-form-submit">
                  <a
                    onClick={sendComentario}
                    className="u-border-2 u-border-black u-btn u-btn-submit u-button-style u-hover-black u-none u-text-black u-text-hover-white u-btn-1"
                  >
                    Enviar
                  </a>
                  <input
                    type="submit"
                    defaultValue="submit"
                    className="u-form-control-hidden"
                    wfd-invisible="true"
                  />
                </div>

                <input
                  type="hidden"
                  defaultValue=""
                  name="recaptchaResponse"
                  wfd-invisible="true"
                />
                <input
                  type="hidden"
                  name="formServices"
                  defaultValue="6be44703-ac0a-e146-4ac4-dcc80a801cc6"
                />
              </form>
            </div>
          </div>
        </div>
        <div className="custom-expanded u-list u-list-1">
          <div className="u-repeater u-repeater-1">
            <div className="u-container-align-left u-container-style u-list-item u-palette-5-dark-3 u-repeater-item u-list-item-1">
              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-2">
                <h5 className="u-align-left u-text u-text-2">
                  <span className="u-file-icon u-icon u-text-palette-3-base">
                    <img src="images/3059407-a33608e5.png" alt="" />
                  </span>
                  &nbsp;Llamanos al
                </h5>
                <p className="u-align-left u-text u-text-3">
                  7712155993 , 7713437831
                </p>
              </div>
            </div>
            <div className="u-container-align-left u-container-style u-list-item u-palette-5-dark-3 u-repeater-item u-list-item-2">
              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-3">
                <h5 className="u-align-left u-text u-text-4">
                  <span className="u-file-icon u-icon u-text-palette-3-base">
                    <img src="images/535239-5de83b82.png" alt="" />
                  </span>
                  &nbsp;Locacion
                </h5>
                <p className="u-align-left u-text u-text-5">
                  Cuauhtémoc 389, 21 Avenida, Huejutla de Reyes Hidalgo, No. 92103-9000
                </p>
              </div>
            </div>
            <div className="u-container-align-left u-container-style u-list-item u-palette-5-dark-3 u-repeater-item u-list-item-3">
              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-4">
                <h5 className="u-align-left u-text u-text-6">
                  <span className="u-file-icon u-icon u-text-palette-3-base u-icon-3">
                    <img src="images/2841985-7e11ec36.png" alt="" />
                  </span>
                  &nbsp;Nuestros Horarios
                </h5>
                <p className="u-align-left u-text u-text-7">
                  Lun – Vie …… 10 am – 8 pm, Sáb, Dom....… Cerrado
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



  );
};

export default UserWindow;