import '../../styles/preguntaSecreta.css'
import React, { useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import md5 from 'md5';

const ValidateCode = () => {
    const navigation = useNavigate();
    // Estados para almacenar el correo electrónico y la contraseña
    const [code, setCode] = useState("");

    // Función para manejar el envío del formulario
    const handleSubmit = () => {
        Swal.fire({
            icon: "info",
            title: `Verificando...`,
            html: "Espere por favor",
            allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
            showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él
        });

        // Simulación de verificación, sustituye por tu lógica real
        setTimeout(() => {
            if (localStorage.getItem('code') === code) {
                Swal.fire({
                    icon: "success",
                    title: `Verificación correcta`,
                    timer: 2000
                }).then(() => {
                    navigation("/ResetPass");
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    title: `Credenciales incorrectas`,
                    html: "No se ha encontrado el usuario",
                    allowOutsideClick: true, // Evita que se cierre haciendo clic fuera del modal
                    showConfirmButton: true, // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él
                });
            }
        }, 1000); // Tiempo de espera simulado (1 segundo) antes de verificar las credenciales

    }

    return (
        <div className="container">
            <form className="form-control" action="">
                <p className="title">Se le ha enviado un codigo de confirmacion</p>
                <div className="input-field">


                    <div class="input-field">

                        <label class="label" for="input"></label>
                        <input value={code}
                            onChange={(event) => {
                                setCode(event.target.value);

                            }} required="" class="input" type="text" />
                        <label class="label" for="input">Ingresar Codigo</label>
                    </div>
                </div>

                <button onClick={handleSubmit} className="submit-btn">Validar Codigo</button>
            </form>
        </div>
    );

};

export default ValidateCode;