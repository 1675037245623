import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link, useNavigate } from 'react-router-dom';

const noti = withReactContent(Swal);

const AdminWindow = () => {
    const [datos, setDatos] = useState([]);

    useEffect(() => {
        cargarDatos();
    }, []);

    const cargarDatos = async () => {
        try {
            const response = await Axios.get('https://servermascoteh.onrender.com/getcomentarios');
            const datos = response.data;
            setDatos(datos);
        } catch (error) {
            console.error('Error al cargar datos:', error);
        }
    };

    const handleSave = async () => {
        try {
            await Axios.put('https://servermascoteh.onrender.com/ds', datos);
            console.log('Datos guardados:', datos);
        } catch (error) {
            console.error('Error al guardar datos:', error);
        }
    };

   

    const editarComentario = (dato) => {
        noti.fire({
            title: 'Editar Politica',
            html: `
        <input id="titulo" value="${dato.user}" class="swal2-input" placeholder="Titulo">
        <input id="contenido" value="${dato.email}" class="swal2-input" placeholder="Contenido">
        <input id="fecha" value="${dato.comentario}" class="swal2-input" placeholder="Fecha">
      `,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                const user = Swal.getPopup().querySelector('#titulo').value;
                const email = Swal.getPopup().querySelector('#contenido').value;
                const comentario = Swal.getPopup().querySelector('#fecha').value;

                Axios.put(`https://servermascoteh.onrender.com/ComentarioEdit/${dato._id}`, {
                    user: user,
                    email: email,
                    comentario: comentario,
                }).then(() => {
                    Swal.fire('Dato actualizado', '', 'success');
                    cargarDatos();
                }).catch(error => {
                    console.error('Error al actualizar dato:', error);
                    Swal.fire('Error', 'No se pudo actualizar el dato', 'error');
                });
            },
        });
    };

    const eliminarComentarios = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará el comentario permanentemente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                Axios.delete(`https://servermascoteh.onrender.com/deleteComentario/${id}`).then(() => {
                    cargarDatos();
                    Swal.fire(
                        'Comentario Eliminado',
                        'El comentario se ha eliminado correctamente.',
                        'success'
                    );
                }).catch(error => {
                    console.error('Error al eliminar politica:', error);
                    Swal.fire(
                        'Error',
                        'No se pudo eliminar el comentario.',
                        'error'
                    );
                });
            }
        });
    };

    return (
        <section className="u-align-center u-clearfix u-white u-section-addP" id="sec-ad19">
            <div className="u-expanded-width u-table u-table-responsive u-table-1">

                <h2 style={{

                    color: "#fff",
                    textShadow: "0 0 10px #000, 0 0 20px #000, 0 0 30px #000, 0 0 40px #000, 0 0 50px #000, 0 0 60px #000, 0 0 70px #000, 0 0 80px #000",
                    fontFamily: "ADLaM Display",
                    textAlign: "center"
                }} className="">Comentarios Recibidos</h2>
                <br />

                <br />
                <div className='u-table-container'>
                    <table className="u-table-entity">
                        <thead className="u-palette-4-base u-table-header u-table-header-1">
                            <tr style={{ height: 21 }}>

                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Usuario
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Email
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Comentarios
                                </th>

                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody className="u-table-body" style={{ backgroundColor: 'white' }}>
                            {datos.map(datos => (
                                <tr key={datos._id} style={{ height: 76 }}>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{datos.user}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{datos.email}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{datos.comentario}</td>
                                    



                                    <td style={{ display: "flex" }} className="u-border-1 u-border-grey-30 u-table-cell">
                                        <button onClick={() => editarComentario(datos)} title="filter" class="filter">
                                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="25" width="25">
                                                {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                                                <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
                                            </svg>
                                        </button>

                                        <button onClick={() => eliminarComentarios(datos._id)} class="tooltip">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" height="25" width="25">
                                                <path fill="#6361D9" d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z" clip-rule="evenodd" fill-rule="evenodd"></path>
                                            </svg>
                                            <span class="tooltiptext">remove</span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default AdminWindow;
