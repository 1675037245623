import React, { useState, useEffect } from 'react';
import '../styles/appControl.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';

/* images */
import aguaO from '../resources/appControlResources/agua0.png'
import agua10 from '../resources/appControlResources/agua10.png'
import agua20 from '../resources/appControlResources/agua20.png'
import agua30 from '../resources/appControlResources/agua30.png'
import agua40 from '../resources/appControlResources/agua40.png'
import agua50 from '../resources/appControlResources/agua50.png'
import agua60 from '../resources/appControlResources/agua60.png'
import agua70 from '../resources/appControlResources/agua70.png'
import agua80 from '../resources/appControlResources/agua80.png'
import agua90 from '../resources/appControlResources/agua90.png'
import aguare from '../resources/appControlResources/aguare.png'

import com0 from '../resources/appControlResources/_com0.png'
import com10 from '../resources/appControlResources/com10.png'
import com20 from '../resources/appControlResources/com20.png'
import com30 from '../resources/appControlResources/com30.png'
import com40 from '../resources/appControlResources/com40.png'
import com50 from '../resources/appControlResources/com50.png'
import com60 from '../resources/appControlResources/com60.png'
import com70 from '../resources/appControlResources/com70.png'
import com80 from '../resources/appControlResources/com80.png'
import com90 from '../resources/appControlResources/com90.png'
import core from '../resources/appControlResources/core.png';
import App from '../App';

const AppControl = () => {
  const [data, setData] = useState(null);
  const [waterLevel, setWaterLevel] = useState(0);
  const [foodLevel, setFoodLevel] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://servermascoteh.onrender.com/getDispositivo');
        if (response.status === 200) {
          const jsonData = response.data;
          setData(jsonData);
          setWaterLevel(jsonData.Porcentaje_agua);
          setFoodLevel(jsonData.Porcentaje_alimento);
        } else {
          console.error('Error al obtener datos del servidor:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error de red:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleClick = async () => {
    try {
      const response = await axios.get('https://servermascoteh.onrender.com/getHistorial');
      const historial = response.data.slice(-10);

      const swalContent = historial.map((item, index) => (
        `<li key=${index}>
          Acción: ${item.Accion}, 
          Hora: ${item.Hora}, 
          Fecha: ${item.Fecha}
        </li>`
      ));

      Swal.fire({
        width: "600px",
        title: 'Últimos 10 Registros del Historial',
        html: `<ul>${swalContent.join('')}</ul>`,
        icon: 'info'
      });
    } catch (error) {
      console.error('Error al obtener el historial:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al obtener el historial',
        icon: 'error'
      });
    }
  };

  const onLight = async () => {
    await sendDataToServer("ON");
  };

  const onMove = async () => {
    await sendDataToServer("MOVE");
  };

  const sendDataToServer = async (state) => {
    try {
      const response = await axios.post('https://servermascoteh.onrender.com/app/data-afnpg/endpoint/EcoNido', {
        estado: state
      });
      await axios.post("https://servermascoteh.onrender.com/InsertHistoric", {
        Accion: state
      });
      console.log(state);

      if (response.status === 200) {
        console.log(`Datos ${state === 'ON' ? 'Encendido' : state === 'MOVE' ? 'Movido' : 'Apagado'} enviados al servidor con éxito`);
      } else {
        console.error('Error al enviar datos al servidor:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };
  return (
    <div className="controlContainer">
      <div className="horizontalContainer">
        <div className="progressWidget">
          <div style={{ display: "flex" }}>
            <CircularProgressbar value={waterLevel} text={`${waterLevel}%`} />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
            {
                  waterLevel == 100 ? (
                    <div>
                      <img src={aguare} alt="Nivel 10" />
                      <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                    </div>
                  ) : waterLevel >= 90 ? (
                    <div>
                      <img src={agua90} alt="Nivel 10" />
                      <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                    </div>
                  ) : waterLevel >= 80 ? (
                    <div>
                      <img src={agua80} alt="Nivel 9" />
                      <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                    </div>
                  ) : waterLevel >= 70 ? (
                    <div>
                      <img src={agua70} alt="Nivel 8" />
                      <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                    </div>
                  ) : waterLevel >= 60 ? (
                    <div>
                      <img src={agua60} alt="Nivel 7" />
                      <FontAwesomeIcon color='#FFD300' icon={faExclamationTriangle} />
                    </div>
                  ) : waterLevel >= 50 ? (
                    <div>
                      <img src={agua50} alt="Nivel 6" />
                      <FontAwesomeIcon color='#FFD300' icon={faExclamationTriangle} />
                    </div>
                  ) : waterLevel >= 40 ? (
                    <div>
                      <img src={agua40} alt="Nivel 5" />
                      <FontAwesomeIcon color='#FFD300' icon={faExclamationTriangle} />
                    </div>
                  ) : waterLevel >= 30 ? (
                    <div>
                      <img src={agua30} alt="Nivel 4" />
                      <FontAwesomeIcon color='red' icon={faExclamationTriangle} />
                    </div>
                  ) : waterLevel >= 20 ? (
                    <div>
                      <img src={agua20} alt="Nivel 3" />
                      <FontAwesomeIcon color='red' icon={faExclamationTriangle} className="fa-wrench" />
                    </div>
                  ) : waterLevel >= 10 ? (
                    <div>
                      <img src={agua10} alt="Nivel 2" />
                      <FontAwesomeIcon color='red' icon={faExclamationTriangle} className="fa-wrench" />
                    </div>
                  ) : (
                    <div>
                      <img src={aguaO} alt="Nivel 1" />
                      <FontAwesomeIcon color='red' icon={faExclamationTriangle} className="fa-wrench" />
                    </div>
                  )}
            </div>
          </div>
          <button  style={{margin:"0 auto"}} className="button" onClick={onLight}>Sacar agua</button>
        </div>
        <div className="progressWidget">

        <div style={{ display: "flex" }}>
            <CircularProgressbar value={foodLevel} text={`${foodLevel}%`} />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              {
                foodLevel == 100 ? (
                  <div>
                    <img src={core} alt="Nivel 10" />
                    <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                  </div>
                ) : foodLevel >= 90 ? (
                  <div>
                    <img src={com90} alt="Nivel 10" />
                    <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                  </div>
                ) : foodLevel >= 80 ? (
                  <div>
                    <img src={com80} alt="Nivel 9" />
                    <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                  </div>
                ) : foodLevel >= 70 ? (
                  <div>
                    <img src={com70} alt="Nivel 8" />
                    <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                  </div>
                ) : foodLevel >= 60 ? (
                  <div>
                    <img src={com60} alt="Nivel 7" />
                    <FontAwesomeIcon color='#FFD300' icon={faExclamationTriangle} />
                  </div>
                ) : foodLevel >= 50 ? (
                  <div>
                    <img src={com50} alt="Nivel 6" />
                    <FontAwesomeIcon color='#FFD300' icon={faExclamationTriangle} />
                  </div>
                ) : foodLevel >= 40 ? (
                  <div>
                    <img src={com40} alt="Nivel 5" />
                    <FontAwesomeIcon color='#FFD300' icon={faExclamationTriangle} />
                  </div>
                ) : foodLevel >= 30 ? (
                  <div>
                    <img src={com30} alt="Nivel 4" />
                    <FontAwesomeIcon color='red' icon={faExclamationTriangle} />
                  </div>
                ) : foodLevel >= 20 ? (
                  <div>
                    <img src={com20} alt="Nivel 3" />
                    <FontAwesomeIcon color='red' icon={faExclamationTriangle} className="fa-wrench" />
                  </div>
                ) : foodLevel >= 10 ? (
                  <div>
                    <img src={com10} alt="Nivel 2" />
                    <FontAwesomeIcon color='red' icon={faExclamationTriangle} className="fa-wrench" />
                  </div>
                ) : (
                  <div>
                    <img src={com0} alt="Nivel 1" />
                    <FontAwesomeIcon color='red' icon={faExclamationTriangle} className="fa-wrench" />

                  </div>
                )}
            </div>
          </div>
          <button style={{margin:"0 auto"}} className="button" onClick={onMove}>Sacar Alimento</button>
        </div>
      </div>
      <div className="historyButtonContainer">
        <button className="button-history" onClick={handleClick}>Historial</button>
      </div>
    </div>
  );
}
export default AppControl;