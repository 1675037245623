import '../../styles/preguntaSecreta.css'
import React, { useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const RecoverPassQuestion = () => {
    const navigation = useNavigate();
    // Estados para almacenar el correo electrónico y la contraseña
    const [email, setUser] = useState("");
    const [emailError, setEmailError] = useState(null);

    // Función para manejar el envío del formulario
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email.trim()) {
            setEmailError('Por favor, ingrese su correo electrónico');
            return;
        }

        setEmailError(null);
        Swal.fire({
            icon: "info",
            title: `Verificando...`,
            html: "Espere Por favor",
            allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
            showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él

        })

        Axios.post("https://servermascoteh.onrender.com/validateEmail", {
            email:email,
        }).then(response => {
            // Verificar si la respuesta del servidor tiene un estado definido y es 200
            if (response && response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: `Verificacion correcta`,
                    timer: 2000

                }).then(() => {
                    localStorage.setItem('passId', response.data._id);
                    localStorage.setItem('emailId', response.data.email);

                    Axios.post(`https://servermascoteh.onrender.com/email`, {
                        email: localStorage.getItem('emailId')

                    }).then(response => {
                        if (response && response.status === 200) {
                            const result = response;
                            const { status, error, token } = result;
                            localStorage.setItem('code', response.data.token);
                            console.log(localStorage.getItem('code'));
                            navigation("/ValidateCode");
                        }

                    })


                });
            } else {
                Swal.fire({
                    icon: "warning",
                    title: `Credenciales Incorrectas`,
                    html: "No se ha encontrado el usuario",
                    allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
                    showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él

                })
            }
        }).catch(error => {
            // Manejar errores de la solicitud
            if (error.response && error.response.status === 404) {
                Swal.fire({
                    icon: "warning",
                    title: `Datos Incorrectos`,
                    html: "Verifique su respuesta",


                })
            } else {
                // Manejar otros errores
                console.error("Error:", error);
                alert(error);
            }
        });
    }

    return (
        <div className="container">
        <form className="form-control" onSubmit={handleSubmit}>
            <p className="title">Recuperación</p>
            <div className="input-field">
                <input
                    value={email}
                    onChange={(event) => setUser(event.target.value)}
                    required=""
                    className="input"
                    type="email" // Cambiado a type="email" para validar el formato del correo electrónico
                />
                <label  className="label" htmlFor="input">Email</label>
            </div>
            {emailError && <p className="error-message">{emailError}</p>} {/* Mostrar el mensaje de error */}
            <button type="submit" className="submit-btn">Validar Email</button>
        </form>
    </div>
    );
};

export default RecoverPassQuestion;