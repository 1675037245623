import React from 'react';
import '../styles/header.css';
import { useState } from 'react';
import logo from '../resources/logo.png'
import Axios from 'axios';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton'
import { CiUser } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosLogOut } from "react-icons/io";

const Header = () => {
    const [username, setUser] = useState("");
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    function handleMouseDown(event) {
        setIsDragging(true);
        setOffset({
            x: event.clientX - position.x,
            y: event.clientY - position.y
        });
    }

    function handleMouseMove(event) {
        if (isDragging) {
            setPosition({
                x: event.clientX - offset.x,
                y: event.clientY - offset.y
            });
        }
    }

    function handleMouseUp() {
        setIsDragging(false);
    }
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {

        setIsOpen(!isOpen);
        return (

            <button style={{ position: "absolute", top: "190px", right: "500px", backgroundColor: "white", height: "50px", display: "flex", alignItems: "center" }} onClick={toggleMenu} className={`menu-toggle ${isOpen ? 'open' : ''}`}>
                <span className="arrow"></span>
                {isOpen ? <IoIosArrowBack />
                    : <IoIosArrowForward />}
            </button>
        )
    };

    const closeMenu = () => {
        setIsOpen(false);
    };
    const hand = () => {
        setIsOpen(true);
    };

    const menu = () => {
        if (localStorage.getItem('user') === 'admin') {

            return (

                <div style={{ cursor: "pointer" }}>
                    <button style={{ position: "absolute", top: "190px", backgroundColor: "white", height: "100px", display: "flex", alignItems: "center" }} onClick={toggleMenu} className={`menu-toggle ${isOpen ? 'open' : ''}`}>
                        <span className="arrow"></span>
                        {isOpen ? <IoIosArrowBack />
                            : <IoIosArrowForward />}
                    </button>
                    <div style={{ padding: "20px" }} className={`menu ${isOpen ? 'open' : ''}`} onClick={closeMenu}>
                        <ul>
                            <h3 style={{ color: "white" }}>Bienvenido Admin {localStorage.getItem('username')}</h3>
                            <div style={{ padding: "20px" }} className="search">
                                <div className="search-box">
                                    <div className="search-field">
                                        <input placeholder="Search..." className="input" type="text" />
                                        <div className="search-box-icon">
                                            <button className="btn-icon-content">
                                                <i className="search-icon">
                                                    <svg
                                                        xmlns="://www.w3.org/2000/svg"
                                                        version="1.1"
                                                        viewBox="0 0 512 512"
                                                    >
                                                        <path
                                                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                                                            fill="#fff"
                                                        />
                                                    </svg>
                                                </i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Link to={"adminHome"}> <li>Menu Principal</li></Link>
                            <Link onClick={validateDispo}> <li>Dispositivo</li></Link>
                            <Link to={"adminAddUser"}> <li>Usuarios</li></Link>
                            <Link to={"adminProduct"}> <li>Productos</li></Link>
                            <Link to={"/adminContac"}><li>Contacto</li></Link>
                            <Link to={"/adminPoli"}> <li>Politicas</li></Link>
                            <Link to={"/adminQn"}> <li>Quienes Somos</li></Link>
                            <Link to={"/adminVision"}> <li>Vision</li></Link>
                            <Link to={"/adminMision"}> <li>Mision</li></Link>
                            <Link to={"/adminComentarios"}> <li>Comentario</li></Link>
                            <Link to={"/AdminPregFrec"}> <li>Preguntas</li></Link>
                            <Link to={"/adminTeam"}> <li>Equipo</li></Link>
                            <IoIosLogOut size={30} color='white' onClick={logout} />

                        </ul>
                    </div>
                </div>

            );
        } else if (localStorage.getItem('user') === 'usuario') {
            return (

                <div style={{ cursor: "pointer" }}>
                    <button style={{position: "absolute", top: "190px", backgroundColor: "white", height: "100px", display: "flex", alignItems: "center" }} onClick={toggleMenu} className={`menu-toggle ${isOpen ? 'open' : ''}`}>
                        <span className="arrow"></span>
                        {isOpen ? <IoIosArrowBack   />
                            : <IoIosArrowForward />}
                    </button>
                    <div className={`menu ${isOpen ? 'open' : ''}`} onClick={closeMenu}>
                        <ul>
                            <h3 style={{ color: "white" }}>Bienvenido {localStorage.getItem('username')}</h3>
                            <div style={{ padding: "20px" }} className="search">
                                <div className="search-box">
                                    <div className="search-field">
                                        <input placeholder="Search..." className="input" type="text" />
                                        <div className="search-box-icon">
                                            <button className="btn-icon-content">
                                                <i className="search-icon">
                                                    <svg
                                                        xmlns="://www.w3.org/2000/svg"
                                                        version="1.1"
                                                        viewBox="0 0 512 512"
                                                    >
                                                        <path
                                                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                                                            fill="#fff"
                                                        />
                                                    </svg>
                                                </i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Link to={"/Perfil"}> <li>Perfil</li></Link>
                            <Link onClick={validateDispo}> <li>Dispositivo</li></Link>
                            <IoIosLogOut size={30} color='white' onClick={logout} />


                        </ul>
                    </div>
                </div>

            ); // O cualquier otro elemento que desees mostrar para usuarios no administradores
        }
    }

    const handleSwal = () => {
        if (localStorage.getItem('user') === null) {
            Swal.fire({
                icon: "error",
                title: 'Inicie Sesion por favor...',
                showCancelButton: false,

                showConfirmButton: false,

                showCloseButton: true,

            })
        }
        else
            Swal.fire({
                title: 'Redirigiendo :)',
                timer: 2000,

            }).then(() => {
                navigation("/Perfil");
            });
    };

    const validateDispo = () => {

        Axios.post("https://servermascoteh.onrender.com/GetDispobyId", {
            username: localStorage.getItem('id_User')

        }).then(response => {
            // Verificar si la respuesta del servidor tiene un estado definido y es 200
            if (response && response.status === 200) {
                // Aquí puedes verificar el tipo de usuario}

                console.log(response.data.dispositivoID);

                if (response.data.dispositivoID !== "") {


                    navigation("/AppControl");


                } else {

                    Swal.fire({
                        title: `No tiene un Dispositivo Asignado`,
                        html: "Sera redirigido a la pestaña de asignacion....",
                        timer: 3000
                    }).then(() => {
                        localStorage.setItem('token', response.data.token);
                        navigation("/Home");
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'El usuario no Existe',
                    text: 'Las contraseñas no coinciden.',
                    timer: 3000
                });
            }
        }).catch(error => {
            // Manejar errores de la solicitud
            if (error.response && error.response.status === 404) {
                alert("Usuario no encontrado");
            } else {
                // Manejar otros errores
                Swal.fire({
                    title: `No tiene un Dispositivo Asignado`,
                    html: "Sera redirigido a la pestaña de asignacion....",
                    timer: 3000
                }).then(() => {

                    navigation("/Home");
                });
            }
        });
    }
    const navigation = useNavigate();
    const navbar = () => {

        return (

            <Nav style={{ fontSize: "" }} className="mr-auto">

                <Link to="/Home" style={{ fontSize: "25px", color: "black", marginRight: '30px' }}>Inicio</Link> {/* Aplica un margen derecho de 10px */}
                <Link to="/Productos" style={{ fontSize: "25px", color: "black", marginRight: '30px' }}>Tienda</Link> {/* Aplica un margen derecho de 10px */}
                <Link to="/QuienesSomos" style={{ fontSize: "25px", color: "black", marginRight: '30px' }}>Quienes somos?</Link> {/* Aplica un margen derecho de 10px */}
                <Link to="/Contacto" style={{ fontSize: "25px", color: "black", marginRight: '30px' }}>Contacto</Link> {/* Aplica un margen derecho de 10px */}

            </Nav>
        )

    }





    const logout = () => {
        // Eliminar el token de sesión del almacenamiento local
        localStorage.removeItem('token');
        (localStorage.removeItem('rol'))
        localStorage.removeItem('user');
        console.log(localStorage.removeItem('user'));
        localStorage.removeItem('client')
        localStorage.clear();
        console.log("items removidos");
        // Redireccionar a la página de inicio de sesión
        navigation("/InicioSesion");
    }
    const LogoutButton = () => {
        return (
            <>

                <a

                    onClick={logout}
                    className="u-border-2 u-border-black u-btn u-button-style u-hover-black u-none u-text-hover-white u-btn-1"
                >
                    Cerrar sesion

                </a>
                <a

                    onClick={validateDispo}
                    className="u-border-2 u-border-black u-btn u-button-style u-hover-black u-none u-text-hover-white u-btn-2" to="/Registro    "
                >
                    Dispositivo

                </a>



            </>
        );
    }
    const isAuthenticated = () => {
        // Verificar si el token de sesión está presente en el almacenamiento local
        return localStorage.getItem('token') !== null;
    }



    return (
        <header
            className="u-clearfix u-gradient u-header u-sticky u-sticky-381b u-header"
            id="sec-cc35"
        >
            <div className="u-clearfix u-sheet  u-valign-middle-xl u-sheet-1">
                <Link
                    to={"/Home"}
                    className="u-image u-logo u-image-1"
                    data-image-width={648}
                    data-image-height={385}
                >
                    <img src={logo} className="u-logo-image u-logo-image-1" />
                </Link>

                {localStorage.getItem('token') == null && (

                    <Link style={{}} className="u-border-2 u-border-black u-btn u-button-style u-hover-black u-none u-text-hover-white u-btn-1" to="/InicioSesion">
                        INICIAR SESION
                    </Link>

                )}

                {/* Aquí se muestra el botón de registro si no hay token de sesión */}
                {localStorage.getItem('token') == null && (
                    <Link className="u-border-2 u-border-black u-btn u-button-style u-hover-black u-none u-text-hover-white u-btn-2" to="/Registro">
                        Registrarse
                    </Link>
                )}
                {localStorage.getItem('token') && <LogoutButton />}
                <br></br>
                <div>
                    <Navbar.Collapse className='' id="basic-navbar-nav">
                        {navbar()}
                    </Navbar.Collapse>
                </div>

            </div>
            {menu()}
        </header>





    );
};



export default Header;