import React from 'react';
import '../styles/registro.css';
import Axios from 'axios';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import md5 from 'md5';

const noti = withReactContent(Swal);


export const Registro = () => {

    const navigation = useNavigate();
    const [nombre, setNombre] = useState("");
    const [apePa, setapePa] = useState("");
    const [apeMa, setapeMa] = useState("");
    const [email, setEmail] = useState("");
    const [user, setUser] = useState("");
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");







    // Función para verificar si el correo electrónico es válido
    const isValidEmail = (email) => {
        return emailRegex.test(email);
    };
    const hashedPassword = md5(pass);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[A-Za-z\s]+$/;

    const add = (event) => {
        event.preventDefault();
        if (!nombre || !apePa || !apeMa || !user || !email || !pass || !confirmPass) {
            Swal.fire({
                icon: 'error',
                title: 'Error de registro',
                text: 'Por favor completa todos los campos.',
                timer: 3000
            });
        }
        else if (!nameRegex.test(nombre) || !nameRegex.test(apePa) || !nameRegex.test(apeMa)) {
            Swal.fire({
                icon: 'error',
                title: 'Error de registro',
                text: 'El nombre y los apellidos no deben contener números.',
                timer: 3000
            });
        } else if (!isValidEmail(email)) {

            Swal.fire({
                icon: 'error',
                title: 'Error de registro',
                text: 'Por favor ingresa un correo electrónico válido.',
                timer: 3000
            });
        }
        else if (pass !== confirmPass) {
            Swal.fire({
                icon: 'error',
                title: 'Error de registro',
                text: 'Las contraseñas no coinciden.',
                timer: 3000
            });
        }
        else if (pass.length < 8) {
            Swal.fire({
                icon: 'error',
                title: 'Error de registro',
                text: 'La contraseña debe tener al menos 8 caracteres.',
                timer: 3000
            });
        } else {
            Axios.post("https://servermascoteh.onrender.com/Insert", {
                nombre: nombre,
                apePa: apePa,
                apeMa: apeMa,
                user: user,
                question: question,
                answer: answer,
                email: email,
                pass: hashedPassword
            }).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: `Registro Exitoso`,
                    text: `Bienvenido ${user}`,
                    timer: 3000
                }).then(() => {
                    navigation("/InicioSesion");
                });
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error de registro',
                        text: 'Usuario ya Registrado',
                        timer: 3000
                    });
                } else {
                    console.error("Error:", error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error de registro',
                        text: 'Usuario ya Registrado',
                        timer: 3000
                    });
                }
            });
        }
    };
    return (
        <section
            className="u-align-center u-clearfix u-gradient u-register-1"
            id="carousel_c4a6"
        >
            <div className="u-clearfix u-sheet u-sheet-1">
                <h2 className="u-custom-font u-text u-text-default u-text-1" style={{ color: 'white', fontFamily: 'cursive' }}>
                    Masco-Registro
                </h2>
                <div className="bloque registro u-align-center u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-radius-30 u-shape-round  u-group-1">
                    <div style={{backgroundColor:"#F1F1F1"}} className="u-container-layout u-container-layout-1">
                        <div className="custom-expanded u-form u-form-1">
                            <form
                                action="https://forms.nicepagesrv.com/v2/form/process"
                                className="u-clearfix u-form-spacing-15 u-form-vertical u-inner-form"
                                source="email"
                                name="form"
                                style={{ padding: 0 }}
                            >
                                <div className="u-form-group u-form-name u-form-partition-factor-2">
                                    <label htmlFor="name-4c18" className="u-label u-label-1">
                                        Nombre
                                    </label>
                                    <input

                                        required
                                        value={nombre} onChange={(event) => {
                                            setNombre(event.target.value);
                                        }}
                                        type="text"
                                        placeholder="Ingresa tu Nombre Completo"
                                        id="name-4c18"
                                        name="name"
                                        className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"

                                    />
                                </div>
                                <div className="u-form-group u-form-partition-factor-2 u-form-group-2">
                                    <label htmlFor="text-b7b1" className="u-label u-label-2">
                                        Apellido Paterno
                                    </label>
                                    <input
                                        required
                                        value={apePa}
                                        onChange={(event) => {
                                            setapePa(event.target.value);
                                        }}
                                        type="text"
                                        placeholder="Ingresa tu Apellido Paterno"
                                        id="text-b7b1"
                                        name="text"
                                        className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"
                                    />
                                </div>
                                <div className="u-form-group u-form-partition-factor-2 u-form-group-3">
                                    <label htmlFor="text-078a" className="u-label u-label-3">
                                        Apellido Materno
                                    </label>
                                    <input
                                        required
                                        value={apeMa}
                                        onChange={(event) => {
                                            setapeMa(event.target.value);
                                        }}
                                        type="text"
                                        placeholder="Ingresa tu Apellido Materno"
                                        id="text-078a"
                                        name="text-1"
                                        className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"
                                    />
                                </div>
                                <div className="u-form-group u-form-partition-factor-2 u-form-group-4">
                                    <label htmlFor="text-7b0e" className="u-label u-label-4">
                                        Usuario
                                    </label>
                                    <input
                                        required
                                        value={user}
                                        onChange={(event) => {
                                            setUser(event.target.value);
                                        }}
                                        type="text"
                                        placeholder="Ingrese su Usuario"
                                        id="text-7b0e"
                                        name="text-4"
                                        className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"
                                    />
                                </div>
                                <div className="u-form-email u-form-group u-form-partition-factor-2">
                                    <label htmlFor="email-4c18" className="u-label u-label-5">
                                        Correo Electronico
                                    </label>
                                    <input
                                        value={email}
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                        }}
                                        type="email"
                                        placeholder="Ingrese su Correo Electronico"
                                        id="email-4c18"
                                        name="email"
                                        className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"
                                        required=""
                                    />
                                </div>
                                <div className="u-form-group u-form-partition-factor-2 u-form-select u-form-group-6">
                                    <label htmlFor="select-508d" className="u-label u-label-6">
                                        Pregunta Secreta
                                    </label>
                                    <div className="u-form-select-wrapper">
                                        <select
                                            value={question}
                                            onChange={(event) => {
                                                setQuestion(event.target.value);
                                            }}
                                            id="select-508d"
                                            name="question"
                                            className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"
                                        >
                                            <option value="Quien es Mejor Amigo?" data-calc="">
                                               Quien es Mejor Amigo?
                                            </option>
                                            <option value="Cual es el nombre de su Mascota?" data-calc="">
                                               Cual es el nombre de su Mascota?
                                            </option>
                                            <option value="Nombre de algun Familiar?" data-calc="">
                                               Nombre de algun Familiar?
                                            </option>
                                        </select>
                                        <svg
                                            className="u-caret u-caret-svg"
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="16px"
                                            height="16px"
                                            viewBox="0 0 16 16"
                                            style={{ fill: "currentColor" }}
                                            xmlSpace="preserve"
                                        >
                                            <polygon className="st0" points="8,12 2,4 14,4 " />
                                        </svg>
                                    </div>
                                </div>
                                <div className="u-form-group u-form-group-7">
                                    <label htmlFor="text-7e01" className="u-label u-label-7">
                                        Respuesta
                                    </label>
                                    <input
                                        value={answer}
                                        onChange={(event) => {
                                            setAnswer(event.target.value);
                                        }}
                                        type="text"
                                        placeholder="Ingrese la respuesta de su pregunta"
                                        id="text-7e01"
                                        name="text-5"
                                        className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"
                                    />
                                </div>

                                <div className="u-form-group u-form-partition-factor-2 u-form-group-9">
                                    <label htmlFor="text-5847" className="u-label u-label-9">
                                        Contraseña
                                    </label>
                                    <input
                                        value={pass}
                                        onChange={(event) => {
                                            setPass(event.target.value);
                                        }}
                                        type="password"
                                        placeholder="Ingrese su Contraseña"
                                        id="text-5847"
                                        name="text-2"
                                        className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"
                                    />
                                </div>
                                <div className="u-form-group u-form-partition-factor-2 u-form-group-8">
                                    <label htmlFor="text-238e" className="u-label u-label-8">
                                        Confirmar Contraseña
                                    </label>
                                    <input
                                        onChange={(e) => setConfirmPass(e.target.value)}
                                        type="password"
                                        id="text-238e"
                                        name="text-3"
                                        className="u-border-2 u-border-white u-input u-input-rectangle u-radius-10"
                                        placeholder="Ingrese su Contraseña Nuevamente"
                                    />
                                </div>
                                <div className="u-align-right u-form-group u-form-submit">
                                    <a
                                        onClick={(event) => add(event)}

                                        className="u-active-custom-color-4 u-border-5 u-border-active-white u-border-hover-white u-border-white u-btn u-btn-round u-btn-submit u-button-style u-custom-color-2 u-hover-custom-color-3 u-radius-10 u-text-hover-black u-btn-1"
                                    >
                                        Registrarse
                                    </a>
                                    <input
                                        type="submit"
                                        defaultValue="submit"
                                        className="u-form-control-hidden"
                                    />
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    );
}

export default Registro;