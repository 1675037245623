import '../../styles/preguntaSecreta.css'
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const RecoverPassQuestion = () => {
    const navigation = useNavigate();
    // Estados para almacenar el correo electrónico y la contraseña
    const [option, setSelectedOption] = useState("");
    const [answer, setAnswer] = useState("");
    const [datos, setData] = useState([]);

    const obtenerUsuario = async () => {
        try {
            const idUsuario = localStorage.getItem('id_User');
            const respuesta = await Axios.get(`https://servermascoteh.onrender.com/getUserById/${localStorage.getItem('id_User')}`);
            setData(respuesta.data);

        } catch (error) {
            console.error("Error al obtener usuario:", error);
        }
    };
    useEffect(() => {
        obtenerUsuario();
    }, []);
    // Función para manejar el envío del formulario
    const handleSubmit = () => {
        Swal.fire({
            icon: "info",
            title: `Verificando...`,
            html: "Espere Por favor",
            allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
            showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él

        })

        Axios.post("https://servermascoteh.onrender.com/validateAnswer", {
            option: localStorage.getItem('userQuestion'),
            answer: answer,
        }).then(response => {
            // Verificar si la respuesta del servidor tiene un estado definido y es 200
            if (response && response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: `Verificacion correcta`,
                    timer: 2000

                }).then(() => {
                    localStorage.setItem('passId', response.data._id);
                    localStorage.setItem('emailId', response.data.email);

                    Axios.post(`https://servermascoteh.onrender.com/email`, {
                        email: localStorage.getItem('emailId')

                    }).then(response => {
                        if (response && response.status === 200) {
                            const result = response;
                            const { status, error, token } = result;
                            localStorage.setItem('code', response.data.token);
                            console.log(localStorage.getItem('code'));
                            navigation("/ValidateCode");
                        }

                    })


                });
            } else {
                Swal.fire({
                    icon: "warning",
                    title: `Credenciales Incorrectas`,
                    html: "No se ha encontrado el usuario",
                    allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
                    showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él

                })
            }
        }).catch(error => {
            // Manejar errores de la solicitud
            if (error.response && error.response.status === 404) {
                Swal.fire({
                    icon: "warning",
                    title: `Datos Incorrectos`,
                    html: "Verifique su respuesta",


                })
            } else {
                // Manejar otros errores
                console.error("Error:", error);
                alert(error);
            }
        });
    }

    return (
        <div className="container">
            <form className="form-control" action="">
                <p className="title">RECUPERACION</p>
                <div className="input-field">

                    <div class="input-field">
                        <input readOnly value={localStorage.getItem('userQuestion')}
                            onChange={(event) => {
                                setAnswer(event.target.value);

                            }} required="" class="input" type="text" />
                        <label class="label" for="input">Pregunta</label>
                    </div>
                    <div class="input-field">
                        <input value={datos.answer}
                            onChange={(event) => {
                                setAnswer(event.target.value);

                            }} required="" class="input" type="text" />
                        <label class="label" for="input">Respuesta</label>
                    </div>
                </div>

                <button onClick={handleSubmit} className="submit-btn">Validar Usuario</button>
            </form>
        </div>
    );

};

export default RecoverPassQuestion;