import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const Politicas = () => {
  const [Politica, setPoliticas] = useState([]);

  useEffect(() => {
    // Realizar la consulta para obtener las políticas de la empresa
    Axios.get('https://servermascoteh.onrender.com/getPoliticas')
      .then(response => {
        const PoliticaData = response.data; // Obtener el primer objeto de la respuesta
        setPoliticas(PoliticaData);
        console.log(Politica.data);
      })
  }, []);

  return (
    <section style={{ marginTop: "-90px" }} className="u-align-left u-clearfix u-section-qn3" id="sec-9359">
      <div className="u-clearfix u-sheet u-sheet-1">
        <h2 style={{ marginBottom: "50px" }} className="u-text u-text-1">Politicas </h2>
        <div style={{ columnCount: 3, textAlign: "center", marginTop: "-20px" }} className="fr-view u-clearfix u-rich-text u-text u-text-2">
          {Politica.map((Politica, index) => (
            <div style={{ alignContent: "center" }} key={index}>
              <h4 style={{ textAlign: "center" }}> {Politica.titulo}</h4>
              <p style={{ textAlign: "center" }}>{Politica.contenido}</p>
              <p style={{ textAlign: "center" }}>{Politica.fecha_creacion}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Politicas;

