import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import '../styles/quienesSomos.css';


export const QuienesSomos = () => {
  const [Vision, setVision] = useState({ descripcion: '' });
  const [Mision, setMision] = useState({ descripcion: '' });
  const [QuieneSomos, setQuienesSomos] = useState({ descripcion: '', antecedentes: '' });
  const [Politica, setPoliticas] = useState([]);

  useEffect(() => {
    // Realizar la consulta para obtener la visión
    Axios.get('https://servermascoteh.onrender.com/getVision')
      .then(response => {
        const visionData = response.data[0]; // Obtener el primer objeto de la respuesta
        setVision(visionData); // Establecer el estado Vision con los datos de visión
        console.log(visionData.descripcion);
      })
    Axios.get('https://servermascoteh.onrender.com/getMision')
      .then(response => {
        const MisionData = response.data[0]; // Obtener el primer objeto de la respuesta
        setMision(MisionData); // Establecer el estado Vision con los datos de visión
        console.log(MisionData.descripcion);
      })
    Axios.get('https://servermascoteh.onrender.com/getQn')
      .then(response => {
        const qnData = response.data[0]; // Obtener el primer objeto de la respuesta
        setQuienesSomos(qnData); // Establecer el estado Vision con los datos de visión
        console.log(qnData.descripcion);
      })
      .catch(error => {
        console.error('Error al obtener datos de visión:', error);
      });
    Axios.get('https://servermascoteh.onrender.com/getPoliticas')
      .then(response => {
        const PoliticaData = response.data; // Obtener el primer objeto de la respuesta
        setPoliticas(PoliticaData); 
        console.log(Politica.data);
      })
  }, []);
  return (
    <>
      <div className='ucontainer-qn'>
        <section className="u-align-left u-clearfix u-section-qn1" id="sec-cf3b">
          <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <div className="u-align-center u-container-style u-grey-5 u-group u-group-1">
              <div style={{ marginRight: "-20px" }} className="u-container-layout u-container-layout-1">
                <h1 className="u-align-center u-text u-text-default u-text-1">
                  Mision
                </h1>
                <p className="u-align-center u-text u-text-2">
                  {Mision.descripcion}
                </p>
              </div>
            </div>
            <div style={{ marginLeft: "" }} className="u-align-center u-container-style u-grey-5 u-group u-group-2">
              <div className="u-container-layout u-container-layout-2">
                <h1 className="u-align-center u-text u-text-default u-text-3">
                  Vision
                </h1>
                <p  className="u-align-center u-text u-text-4">
                  {Vision.descripcion}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{marginInline:"none", marginTop: "-70px" }} className="u-align-left u-clearfix u-section-qn2" id="sec-ee7c">
          <div className="u-clearfix u-sheet u-sheet-1">
            <div className="fr-view u-clearfix u-rich-text u-text u-text-1">
              <h2 style={{ textAlign: "center" }}>
                <span style={{ lineHeight: 2.0 }}>
                   Descripcion
                </span>
              </h2>
              <p style={{ margin:"40px", textAlign: "left" }}>
                <span style={{textAlign:"center",lineHeight: 2.0 }}>
                  {QuieneSomos.descripcion}. &nbsp;
                </span>
              </p>
              <h2 style={{ textAlign: "center"  }}>
                <span style={{ lineHeight: 2.0 }}>
                  
                  Antecedentes
                </span>
              </h2>
              <p style={{margin:"40px", textAlign: "left" }}>
                <span style={{ lineHeight: 2.0 }}>
                  {QuieneSomos.antecedentes}
                </span>
              </p>
            </div>
          </div>
        </section>
    

      </div>
    </>

  );
};

export default QuienesSomos;
