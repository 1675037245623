import React from 'react';
import '../styles/iniciosesion.css';
import { useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import md5 from 'md5';


const noti = withReactContent(Swal);
export const InicioSesion = () => {

    const navigation = useNavigate();
    const [username, setUser] = useState("");
    const [password, setPass] = useState("");
    const hashedPassword = md5(password);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Evita que el formulario se envíe automáticamente
            handleSubmit(); // Ejecuta la función handleSubmit
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!username || !password) {
            Swal.fire({
                icon: "info",
                title: `Complete todos los campos`,
                html: "................",
    
    
            })
        } else {
            Swal.fire({
                icon: "info",
                title: `Cargando....`,
                html: "Espere Por favor",
                timerProgressBar:true,
                allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
                showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él
    
            })
    
            Axios.post("https://servermascoteh.onrender.com/GetUser", {
                username: username,
                password: hashedPassword
            }).then(response => {
                // Verificar si la respuesta del servidor tiene un estado definido y es 200
                if (response && response.status === 200) {
    
                    // Aquí puedes verificar el tipo de usuario
                    console.log(response.data.permisos);
                    localStorage.setItem('username',response.data.user)
                    if (response.data.permisos === "admin") {
                        // Si es un administrador, redirige a una vista de administrador
                        Swal.fire({
                            title: `Bienvenido`,
                            html: "<i>Bienvenido " + username + "</i>",
                            timer: 3000
                           
                        })
    
                        localStorage.setItem('rol', response.data.permisos);
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('id_User', response.data._id);
                        localStorage.setItem('user', 'admin');
                        console.log(localStorage.getItem('id_User'));
                        navigation("/adminHome");
    
    
                    } else {
                        // Si no es un administrador, redirige a la página de inicio
                        Swal.fire({
                            title: `Bienvenido`,
                            html: "<i>Bienvenido " + username + "</i>",
                            timer: 3000
                        }).then(() => {
                            localStorage.setItem('id_User', response.data._id);
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('user', 'usuario');
                            
                            navigation("/Home");
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: `Credenciales Incorrectas`,
                        html: "Verifique sus credenciales",
                        allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
                        showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él
    
                    })
                }
    
            }).catch(error => {
                // Manejar errores de la solicitud
                if (error.response && error.response.status === 404) {
                    Swal.fire({
                        icon: "warning",
                        title: `Credenciales Incorrectas`,
                        html: "Verifique sus credenciales",
    
    
                    })
                } else {
                    // Manejar otros errores
                    console.error("Error:", error);
                    alert(error);
                }
            });
        }
    }
    
    const RedirectQuestion = () => {
        const navigation = useNavigate();
        // Aquí puedes especificar la ruta a la que deseas redirigir al usuario

    }

    const handlePasswordRecovery = () => {
        Swal.fire({
            title: 'Como desea recuperar?',
            showCancelButton: true,
            confirmButtonText: 'Por Correo',
            cancelButtonText: 'Por Pregunta Secreta'
        }).then((result) => {
            if (result.isConfirmed) {
                // Aquí puedes agregar la lógica para la recuperación por correo
                navigation('/Correo');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Aquí puedes agregar la lógica para la recuperación por pregunta secreta
                navigation('/PreguntaSecreta');
            }
        });
    }
    return (

        <section className="u-clearfix u-gradient u-section-log" id="sec-2a93">
            <div className="u-clearfix u-sheet u-sheet-log">
                <h2 className="u-custom-font u-text u-text-default u-text-log" style={{ color: 'white', fontFamily: 'cursive' }}>
                    Masco-Inicio
                </h2>

                <div className="u-align-center u-border-log1 u-border-no-left u-border-no-right u-border-no-top u-border-white u-container-style u-custom-border u-expanded-width-xs u-group u-radius u-shape-round  u-group-log">
                    <div style={{ backgroundColor: "#F1F1F1" }} className="u-container-layout u-container-layout-log">
                        <div className="u-form u-login-control u-form-log">
                            <form

                                className="u-clearfix u-form-custom-backend u-form-spacing-log0 u-form-vertical u-inner-form"
                                source="custom"
                                name="form"
                                style={{ padding: 0 }}
                                method="post"
                            >
                                <div className="u-form-group u-form-name u-label-top">
                                    <label htmlFor="username-a30d" className="u-label u-label-log">
                                        Usuario
                                    </label>
                                    <input
                                        value={username}
                                        onChange={(event) => {
                                            setUser(event.target.value);

                                        }}
                                        type="text"
                                        placeholder="Ingresa tu Usuario"
                                        id="username-a30d"
                                        name="username"
                                        className="u-border-8 u-border-white u-input u-input-rectangle u-radius-50 u-input-log"
                                        required=""
                                        
                                    />
                                </div>
                                <div className="u-form-group u-form-password u-label-top">
                                    <label htmlFor="password-a30d" className="u-label u-label-2">
                                        Contraseña
                                    </label>
                                    <input
                                        value={password}
                                        onChange={(event) => {
                                            setPass(event.target.value);

                                        }}
                                        type="password"
                                        placeholder="Ingresa tu Contraseña"
                                        id="text-5847"
                                        name="password"
                                        className="u-border-8 u-border-white u-input u-input-rectangle u-radius-50 u-input-2"
                                        required=""
                                        
                                    />
                                </div>

                                <div style={{ marginTop: "30px" }} className="u-align-left u-form-group u-form-submit u-label-top">
                                    <input


                                        className="u-form-control-hidden"

                                    />

                                    <a
                                        disabled={!username && !password}
                                        onClick={(event) => handleSubmit(event)}
                                        className="u-active-custom-color-4 u-border-none u-btn u-btn-round u-btn-submit u-button-style u-custom-color-2 u-hover-custom-color-3 u-radius-50 u-text-active-black u-text-hover-black u-btn-log"
                                    >
                                        Iniciar Sesion

                                    </a>
                                </div>

                            </form>
                        </div>
                        <div className="u-align-center-xs u-container-style u-expanded-width u-group  u-group-2">
                            <div className="u-container-layout u-container-layout-2">
                                <a
                                    onClick={handlePasswordRecovery}
                                    className="u-border-log u-border-active-palette-2-light-2 u-border-hover-palette-2-light-2 u-btn u-button-style u-login-control u-login-create-account u-none u-text-active-custom-color-4 u-text-black u-text-hover-custom-color-3 u-btn-2"
                                    data-animation-name=""
                                    data-animation-duration={0}
                                    data-animation-delay={0}
                                    data-animation-direction=""
                                >
                                    Olvidaste tu Contraseña?
                                </a>
                                <Link
                                    to={"/Registro"}
                                    className="u-border-log u-border-active-palette-2-light-2 u-border-hover-palette-2-light-2 u-btn u-button-style u-login-control u-login-create-account u-none u-text-active-custom-color-4 u-text-black u-text-hover-custom-color-3 u-btn-3"
                                >
                                    Aun no tienes cuenta?
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>









    );
};

export default InicioSesion;