import React, { useState, useEffect } from 'react';
import '../styles/home.css';
import perroedit from '../resources/perroedit.mp4';

import dispensador from '../resources/dispensador.jpg';
import perrobañandose from '../resources/perobañandose.jpg';
import gatoJugando from '../resources/gatojugando.jpg'
import perrogato from '../resources/perrogatoComiendo.jpg'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import bodyImage from '../resources/pexels-helena-jankovičová-kováčová-16395151.jpg';


import Axios from 'axios';

const Home = () => {
    const [personas, setPersonas] = useState([]);
    const [datos, setData] = useState([]);
    useEffect(() => {
        cargarData();
    }, []);
    const cargarData = async () => {
        try {
            const response = await Axios.get('https://servermascoteh.onrender.com/getTeam');
            setPersonas(response.data);
        } catch (error) {
            console.error('Error al cargar personas:', error);
        }
    };
    return (
        <>
            <>
                <>
                    <section style={{ paddingLeft: "50px" }}
                        className="u-align-left u-clearfix u-container-align-left u-image u-shading u-section-1"
                        id="sec-a0b1"
                    >
                        <div className="u-clearfix u-sheet u-sheet-1">
                            <h1 className="u-align-left u-text u-text-default u-title u-text-1">
                                Todo para tus mascotas
                            </h1>
                            <p className="u-align-left u-large-text u-text u-text-variant u-text-2">
                                Ten una mascota feliz con nuestros productos
                            </p>
                            <Link
                                to="/Productos"
                                className="u-align-left u-btn u-button-style u-palette-2-base u-btn-1"
                            >
                                Ver Mas
                            </Link>
                        </div>
                    </section>
                    <section
                        className="u-align-center-lg u-align-center-md u-align-center-xl u-align-left-sm u-align-left-xs u-clearfix u-section-2"
                        data-image-width={2000}
                        data-image-height={1333}

                        id="sec-886b"
                    >
                        <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                            <h2 className="u-text u-text-default u-text-1">Nuestro producto estrella</h2>
                            <p style={{ textAlign: 'center' }} className="u-text u-text-2">
                                Conoce nuestro dispensador de agua y alimento
                            </p>
                            <div className="data-layout-selected u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                                <div className="u-gutter-0 u-layout">
                                    <div className="u-layout-row">
                                        <div className="u-size-20 u-size-30-md">
                                            <div className="u-layout-col">
                                                <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-20 u-layout-cell-1">
                                                    <div className="u-container-layout u-valign-top u-container-layout-1">
                                                        <h3 style={{ paddingLeft: "10px" }} className="u-text u-text-default u-text-3">
                                                            Control Remoto
                                                        </h3>
                                                        <p style={{ paddingLeft: "10px" }} className="u-text u-text-4">
                                                            Controla nuestro producto via wifi
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-20 u-layout-cell-2">
                                                    <div className="u-container-layout u-valign-top u-container-layout-2">
                                                        <h3 style={{ paddingLeft: "10px" }} className="u-text u-text-default u-text-5">
                                                            Medidor de agua
                                                        </h3>
                                                        <p style={{ paddingLeft: "10px" }} className="u-text u-text-6">
                                                            Mantente informado en todo momento sobre el nivel del agua con nuestros sensores
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-20 u-layout-cell-3">
                                                    <div className="u-container-layout u-valign-top u-container-layout-3">
                                                        <h3 style={{ paddingLeft: "10px" }} className="u-text u-text-default u-text-7">
                                                            Medidor de alimento
                                                        </h3>
                                                        <p style={{ paddingLeft: "10px" }} className="u-text u-text-8">
                                                            Mantente informado en todo momento sobre el nivel del alimento
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="u-size-20 u-size-30-md">
                                            <div className="u-layout-row">
                                                <div
                                                    className="u-align-left u-container-style u-image u-layout-cell u-size-60 u-image-1"
                                                    src=""
                                                    data-image-width={1080}
                                                    data-image-height={1080}
                                                >
                                                    <div
                                                        className="u-container-layout u-valign-top u-container-layout-4"
                                                        src=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="u-size-20 u-size-60-md">
                                            <div className="u-layout-col">
                                                <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-20 u-layout-cell-5">
                                                    <div className="u-container-layout u-valign-top u-container-layout-5">
                                                        <h3 style={{ paddingLeft: "10px" }} className="u-text u-text-default u-text-9">
                                                            Usabilidad
                                                        </h3>
                                                        <p style={{ paddingLeft: "10px" }} className="u-text u-text-10">
                                                            Facil de usar para todo tipo de Usuarios, no te preocupes por el instructivo, tu solo aprieta botones
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-20 u-layout-cell-6">
                                                    <div className="u-container-layout u-valign-top u-container-layout-6">
                                                        <h3 style={{ paddingLeft: "10px" }} className="u-text u-text-default u-text-11">
                                                            App Movil
                                                        </h3>
                                                        <p style={{ paddingLeft: "10px" }} className="u-text u-text-12">
                                                            Conoce nuestra App Movil para controlarlo, descarga nuestra aplicacion movil para poder controlar en todo momento y alimentar a tus mascotas
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-20 u-layout-cell-7">
                                                    <div className="u-container-layout u-valign-top u-container-layout-7">
                                                        <h3 style={{ paddingLeft: "10px" }} className="u-text u-text-default u-text-13">
                                                            Potente Motor
                                                        </h3>
                                                        <p style={{ paddingLeft: "10px" }} className="u-text u-text-14">
                                                            No te preocupes por el alimento, tu solo tiralo!!, nuestro motor tiene las capacidades para moverlo
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="u-align-center u-clearfix u-palette-4-base u-section-2"
                        id="carousel_64cb"
                    >
                        <div className="u-expanded-width u-shape u-shape-rectangle u-white u-shape-1" />
                        <div className="u-black u-container-style u-group u-shape-rectangle u-group-1">
                            <h1 class="fancy">Echa un Vistazo</h1>
                        </div>
                    </section>
                    <section
                        className="u-align-center u-clearfix u-gradient u-section-3"
                        id="carousel_ed1a"
                    >
                        <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                            <h2 className="u-custom-font u-font-playfair-display u-text u-text-default u-text-1">
                                Todo para tu mascota
                            </h2>
                            <p style={{ textAlign: 'center' }} className="u-text u-text-grey-40 u-text-2">
                                Conoce nuestros productos en venta
                            </p>
                            {/*blog*/}
                            {/*blog_options_json*/}
                            {/*{"type":"Recent","source":"","tags":"","count":""}*/}
                            {/*/blog_options_json*/}
                            <div className="u-blog u-expanded-width u-blog-1">
                                <div className="u-list-control" />
                                <div className="u-repeater u-repeater-1">
                                    {/*blog_post*/}
                                    <div className="u-align-center u-blog-post u-container-style u-repeater-item u-video-cover u-repeater-item-1">
                                        <div className="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs u-container-layout-1">
                                            <a className="u-post-header-link" href="blog/enviar-5.html">
                                                {/*blog_post_image*/}
                                                <img
                                                    autoPlay={true}
                                                    playsinline
                                                    muted
                                                    loop

                                                    src={perrobañandose}
                                                    alt=""
                                                    className="u-expanded-width u-image u-image-contain u-image-default u-product-control u-image-3"
                                                    data-image-width={1124}
                                                    data-image-height={1072}
                                                />
                                                {/*/blog_post_image*/}
                                            </a>
                                            {/*blog_post_header*/}
                                            <h4 className="u-blog-control u-text u-text-3">
                                                <a className="u-post-header-link" href="blog/enviar-5.html">
                                                    Productos de limpieza para tu mascota
                                                </a>
                                            </h4>
                                            {/*/blog_post_header*/}
                                            {/*blog_post_metadata*/}
                                            <div className="u-blog-control u-metadata u-text-grey-30 u-metadata-1">
                                                {/*blog_post_metadata_date*/}
                                                <span className="u-meta-date u-meta-icon">Manten a tu mascota limpia en todo momento</span>
                                                {/*/blog_post_metadata_date*/}
                                                {/*blog_post_metadata_comments*/}
                                                {/*/blog_post_metadata_comments*/}
                                            </div>
                                            {/*/blog_post_metadata*/}
                                            {/*blog_post_readmore*/}
                                            <Link
                                                to="/Productos"
                                                className="u-blog-control u-btn u-button-style u-btn-3"
                                                style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '5px',
                                                    background: '#183153',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.2)',
                                                    overflow: 'hidden',
                                                    cursor: 'pointer',
                                                    border: 'none',
                                                    textDecoration: 'none' /* Agregado para quitar el subrayado en el enlace */,
                                                    color: '#fff',
                                                    transition: 'all 0.4s ease-in-out'
                                                }}
                                            >
                                                <span>
                                                    {/*blog_post_readmore_content*/}
                                                    {/*options_json*/}
                                                    {/*{"content":"Read More","defaultValue":"Lee mas"}*/}
                                                    {/*/options_json*/}Ver mas{/*/blog_post_readmore_content*/}
                                                </span>
                                            </Link>
                                            {/*/blog_post_readmore*/}
                                        </div>
                                    </div>
                                    <div className="u-align-center u-blog-post u-container-style u-repeater-item u-video-cover u-repeater-item-3">
                                        <div className="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs u-container-layout-3">
                                            <a className="u-post-header-link" >
                                                {/*blog_post_image*/}
                                                <img
                                                    autoPlay={true}
                                                    playsinline
                                                    muted
                                                    loop

                                                    src={gatoJugando}
                                                    alt=""
                                                    className="u-expanded-width u-image u-image-contain u-image-default u-product-control u-image-3"
                                                    data-image-width={1124}
                                                    data-image-height={1072}
                                                />
                                                {/*/blog_post_image*/}
                                            </a>
                                            {/*blog_post_header*/}
                                            <h4 className="u-blog-control u-text u-text-5">
                                                <a className="u-post-header-link" >
                                                    Productos de entretenimiento para tu mascota
                                                </a>
                                            </h4>
                                            {/*/blog_post_header*/}
                                            {/*blog_post_metadata*/}
                                            <div className="u-blog-control u-metadata u-text-grey-30 u-metadata-3">
                                                {/*blog_post_metadata_date*/}
                                                <span className="u-meta-date u-meta-icon">Deja que tu mascota se divierta con sus juguetes</span>
                                                {/*/blog_post_metadata_date*/}
                                                {/*blog_post_metadata_comments*/}
                                                {/*/blog_post_metadata_comments*/}
                                            </div>
                                            {/*/blog_post_metadata*/}
                                            {/*blog_post_readmore*/}
                                            <Link
                                                to="/Productos"
                                                className="u-blog-control u-btn u-button-style u-btn-3"
                                                style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '5px',
                                                    background: '#183153',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.2)',
                                                    overflow: 'hidden',
                                                    cursor: 'pointer',
                                                    border: 'none',
                                                    textDecoration: 'none' /* Agregado para quitar el subrayado en el enlace */,
                                                    color: '#fff',
                                                    transition: 'all 0.4s ease-in-out'
                                                }}
                                            >
                                                <span>
                                                    {/*blog_post_readmore_content*/}
                                                    {/*options_json*/}
                                                    {/*{"content":"Read More","defaultValue":"Lee mas"}*/}
                                                    {/*/options_json*/}Ver mas{/*/blog_post_readmore_content*/}
                                                </span>
                                            </Link>

                                            {/*/blog_post_readmore*/}
                                        </div>
                                    </div>
                                    <div className="u-align-center u-blog-post u-container-style u-repeater-item u-video-cover u-repeater-item-3">
                                        <div className="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs u-container-layout-3">
                                            <a className="u-post-header-link" >
                                                {/*blog_post_image*/}
                                                <img
                                                    autoPlay={true}
                                                    playsinline
                                                    muted
                                                    loop

                                                    src={perrogato}
                                                    alt=""
                                                    className="u-expanded-width u-image u-image-contain u-image-default u-product-control u-image-3"
                                                    data-image-width={1124}
                                                    data-image-height={1072}
                                                />
                                                {/*/blog_post_image*/}
                                            </a>
                                            {/*blog_post_header*/}
                                            <h4 className="u-blog-control u-text u-text-5">
                                                <a className="u-post-header-link" >
                                                    Alimento para la nutricion de tu mascota
                                                </a>
                                            </h4>
                                            {/*/blog_post_header*/}
                                            {/*blog_post_metadata*/}
                                            <div className="u-blog-control u-metadata u-text-grey-30 u-metadata-3">
                                                {/*blog_post_metadata_date*/}
                                                <span className="u-meta-date u-meta-icon">Nutre a tu mascota con la mejor calidad</span>
                                                {/*/blog_post_metadata_date*/}
                                                {/*blog_post_metadata_comments*/}
                                                {/*/blog_post_metadata_comments*/}
                                            </div>
                                            {/*/blog_post_metadata*/}
                                            {/*blog_post_readmore*/}
                                            <Link
                                                to="/Productos"
                                                className="u-blog-control u-btn u-button-style u-btn-3"
                                                style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '5px',
                                                    background: '#183153',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.2)',
                                                    overflow: 'hidden',
                                                    cursor: 'pointer',
                                                    border: 'none',
                                                    textDecoration: 'none' /* Agregado para quitar el subrayado en el enlace */,
                                                    color: '#fff',
                                                    transition: 'all 0.4s ease-in-out'
                                                }}
                                            >
                                                <span>
                                                    {/*blog_post_readmore_content*/}
                                                    {/*options_json*/}
                                                    {/*{"content":"Read More","defaultValue":"Lee mas"}*/}
                                                    {/*/options_json*/}Ver mas{/*/blog_post_readmore_content*/}
                                                </span>
                                            </Link>
                                            {/*/blog_post_readmore*/}
                                        </div>
                                    </div>
                                    {/*/blog_post*/}
                                    {/*blog_post*/}
                                    {/*/blog_post*/}
                                    {/*blog_post*/}
                                    {/*/blog_post*/}
                                </div>
                                <div className="u-list-control" />
                            </div>
                            {/*/blog*/}
                        </div>
                    </section>

                </>



                <section
                    className="u-align-center u-clearfix u-palette-4-base u-section-2"
                    id="carousel_64cb"
                >
                    <div className="u-expanded-width u-shape u-shape-rectangle u-white u-shape-1" />
                    <h1 class="fancy">Conocenos</h1>
                </section>
                <div className="u-repeater u-repeater-1" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px' }}>
                    {personas.map((persona, index) => (
                        <div key={index} className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div style={{
                                paddingTop: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Centrado vertical
                                justifyContent: 'center', // Centrado horizontal
                            }} className="u-container-layout u-similar-container u-valign-top u-container-layout-1">
                                <img
                                    style={{ width: '100%', height: '100%', maxWidth: '300px', maxHeight: '300px' }} // Estilo para asegurar que la imagen no exceda su contenedor
                                    alt=""
                                    className={`u-image u-image-circle u-image-${index + 1}`}
                                    src={persona.imagen} // Supongamos que la propiedad imagen está disponible en tu objeto persona
                                    data-image-width={500}
                                    data-image-height={500}
                                />
                                <h4 className="u-text u-text-3">{persona.Nombre}</h4> {/* Aquí se utiliza el nombre de la persona */}
                                <h4 className="u-text u-text-3">{persona.Email}</h4> {/* Aquí se utiliza el nombre de la persona */}
                                <h5 className="u-text u-text-3">{persona.Ocupacion}</h5> {/* Supongamos que la propiedad rol también está disponible en tu objeto persona */}
                            </div>
                        </div>
                    ))}
                </div>

            </>

        </>


    );
};
export default Home;