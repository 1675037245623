import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import md5 from 'md5'; // Importar la biblioteca md5
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Perfil.css';
import background from '../resources/backgroundTienda.jpg';
import { FaUserCircle } from "react-icons/fa";
import { VscArrowSwap } from "react-icons/vsc";
import Swal from 'sweetalert2';

const Perfil = () => {
    const [userData, setUserData] = useState({
        nombre: "",
        apePa: "",
        apeMa: "",
        email: "",
        user: "",
        pass: ""
    });

    const [direccionData, setDireccionData] = useState({
        Estado: "",
        Calle: "",
        Cp: "",
        N_Casa: "",
        Referencias: ""
    });

    const [hashedPassword, setHashedPassword] = useState("");
    const [mostrarFormulario, setMostrarFormulario] = useState(true);

    const obtenerUsuario = async () => {
        try {
            const idUsuario = localStorage.getItem('id_User');
            const respuesta = await Axios.get(`https://servermascoteh.onrender.com/getUserById/${localStorage.getItem('id_User')}`);
            setUserData(respuesta.data);
            setDireccionData(respuesta.data.Direccion);
        } catch (error) {
            console.error("Error al obtener usuario:", error);
        }
    };

    const handleChangePassword = async () => {
        try {
            const { value: newPassword } = await Swal.fire({
                width: "700px",
                title: 'Cambiar Contraseña',
                html:
                    '<label for="newPassword">Nueva Contraseña</label>' +
                    '<input type="password" id="newPassword" class="swal2-input" placeholder="Nueva Contraseña"> <br>' +
                    '<label for="confirmPassword">Confirmar Contraseña</label>' +
                    '<input type="password" id="confirmPassword" class="swal2-input" placeholder="Confirmar Contraseña">',
                focusConfirm: false,
                preConfirm: () => {
                    const newPassword = Swal.getPopup().querySelector('#newPassword').value;
                    const confirmPassword = Swal.getPopup().querySelector('#confirmPassword').value;
    
                    if (newPassword !== confirmPassword) {
                        Swal.showValidationMessage('Las contraseñas no coinciden');
                        return false; // Agregamos return false para detener la ejecución si las contraseñas no coinciden
                    }
                    if (newPassword.length < 8) {
                        Swal.showValidationMessage('La contraseña debe tener mínimo 8 caracteres');
                        return false; // Agregamos return false para detener la ejecución si la contraseña es demasiado corta
                    }
                    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
                    if (!specialCharRegex.test(newPassword)) {
                        Swal.showValidationMessage('La contraseña debe tener al menos un carácter especial');
                        return false; // Agregamos return false para detener la ejecución si no hay caracteres especiales
                    }
                    return newPassword;
                }
            });
    
            if (newPassword) {
                const hashedPassword = md5(newPassword);
                const response = await Axios.put(`https://servermascoteh.onrender.com/resetPass/${localStorage.getItem('id_User')}`, {
                    pass: hashedPassword
                });
    
                Swal.fire({
                    icon: 'success',
                    title: '¡Contraseña cambiada!',
                    text: 'Tu contraseña ha sido cambiada exitosamente.',
                    customClass: {
                        container: 'my-swal',
                        title: 'my-title',
                        content: 'my-content'
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un error al cambiar tu contraseña. Por favor, inténtalo de nuevo.',
                customClass: {
                    container: 'my-swal',
                    title: 'my-title',
                    content: 'my-content'
                }
            });
        }
    };
    

    useEffect(() => {
        obtenerUsuario();
    }, []);

    const handleChangeUserData = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeDireccionData = (e) => {
        const { name, value } = e.target;
        setDireccionData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };



    const handleGuardar = async (e) => {
        e.preventDefault();
        try {
            Swal.fire({
                title: 'Actualizando...',
                allowOutsideClick: false,
                timer: 2000, // Tiempo en milisegundos
                timerProgressBar: true,
                customClass: {
                    timerProgressBar: 'swal2-timer-progress-custom'
                },
                onBeforeOpen: () => {
                    Swal.showLoading();
                    const timerProgressBar = Swal.getTimerProgressBar();
                    timerProgressBar.style.height = '30px'; // Puedes ajustar el tamaño aquí
                }
            });
            const usuarioActualizado = {
                nombre: userData.nombre,
                apePa: userData.apePa,
                apeMa: userData.apeMa,
                email: userData.email,
                Direccion: {
                    Estado: direccionData.Estado,
                    Calle: direccionData.Calle,
                    Cp: direccionData.Cp,
                    N_Casa: direccionData.N_Casa,
                    Referencias: direccionData.Referencias
                }
            };
            const respuesta = await Axios.put(`https://servermascoteh.onrender.com/editar/${localStorage.getItem('id_User')}`, usuarioActualizado);
            console.log(respuesta.data);
            Swal.fire(
                'Usuario actualizado correctamente.',
                '',
                'success'
            );
        } catch (error) {
            console.error("Error al editar usuario:", error);
            Swal.fire(
                'Error',
                'Hubo un error al actualizar el usuario. Por favor, intenta nuevamente.',
                'error'
            );
        }
    };
    const toggleFormulario = () => {
        setMostrarFormulario(prevState => !prevState);
    };

    return (
        <section style={{ backgroundColor: "#dedddd", display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <section style={{ backgroundColor: "#dedddd", padding: "2% 5% 5% 5%" }} className="section_form">
                {mostrarFormulario ? (
                    <form style={{ backgroundColor: "#dedddd" }} id="consultation-form" className="feed-form" onSubmit={handleGuardar}>
                        <h2>Perfil</h2>
                        <label>Nombre</label>
                        <input required="" placeholder="Nombre" type="text" name="nombre" value={userData.nombre} onChange={handleChangeUserData} />
                        <label>Apellido Paterno</label>
                        <input name="apePa" required="" placeholder="Apellido Paterno" value={userData.apePa} onChange={handleChangeUserData} />
                        <label>Apellido Materno</label>
                        <input name="apeMa" required="" placeholder="Apellido Materno" type="text" value={userData.apeMa} onChange={handleChangeUserData} />
                        <label>Usuario</label>
                        <input required="" placeholder="Usuario" readOnly type="text" name="user" value={userData.user} onChange={handleChangeUserData} />
                        <label>Correo Electrónico</label>
                        <input name="email" required="" type='email' placeholder="Correo Electrónico" value={userData.email} onChange={handleChangeUserData} />

                        <label>Contraseña</label>
                        <div style={{ display: "flex" }}>
                            <input name="pass" required="" readOnly placeholder="Contraseña" type="password" value={userData.pass} onChange={handleChangeUserData} />
                            <button onClick={handleChangePassword} className="button_submit" type="button">Cambiar Contraseña</button>
                        </div>
                        <VscArrowSwap style={{ display: 'block', margin: 'auto', fontSize: 30 }} onClick={toggleFormulario} />
                        <button className="button_submit" type="submit">Guardar</button>
                    </form>
                ) : (
                    <form style={{ backgroundColor: "#dedddd" }} id="consultation-form" className="feed-form" onSubmit={handleGuardar}>
                        <h2>Dirección</h2>
                        <label>Estado</label>
                        <input required="" placeholder="Estado" type="text" name="Estado" value={direccionData.Estado} onChange={handleChangeDireccionData} />
                        <label>Calle</label>
                        <input required="" placeholder="Calle" type="text" name="Calle" value={direccionData.Calle} onChange={handleChangeDireccionData} />
                        <label>Código Postal</label>
                        <input required
                            ="" placeholder="Código Postal" type="text" name="Cp" value={direccionData.Cp} onChange={handleChangeDireccionData} />
                        <label>Número de Casa</label>
                        <input required="" placeholder="Número de Casa" type="text" name="N_Casa" value={direccionData.N_Casa} onChange={handleChangeDireccionData} />
                        <label>Referencias</label>
                        <input required="" placeholder="Referencias" type="text" name="Referencias" value={direccionData.Referencias} onChange={handleChangeDireccionData} />
                        <VscArrowSwap style={{ display: 'block', margin: 'auto', fontSize: 30 }} onClick={toggleFormulario} />
                        <button className="button_submit" type="submit">Guardar</button>
                    </form>
                )}
            </section>

            <div className="image-container">
                <img src={background} alt="Fondo" />
            </div>
        </section>
    );
}

export default Perfil;
