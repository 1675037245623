import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/adminCss/adminHome.css';
import vaca from '../../resources/videoplayback.mp4'
const adminHome = () => {
    return (
        <section
            className="u-align-center u-clearfix u-container-align-center  u-section-home"
            id="sec-ad19"
        >

            <div className="u-clearfix u-sheet u-sheet-1">
                
                <h2 style={{
                    marginTop: "150px",
                    color: "#fff",
                    textShadow: "0 0 10px #000, 0 0 20px #000, 0 0 30px #000, 0 0 40px #000, 0 0 50px #000, 0 0 60px #000, 0 0 70px #000, 0 0 80px #000",
                    fontFamily: "ADLaM Display",
                    textAlign: "center"
                }} className="u-align-center u-custom-font u-text-home u-text-home-default u-text-home-1">
                    BIENVENIDO ADMINISTRADOR
                </h2>



                <p style={{
                    color: "#fff",
                    textShadow: "0 0 5px #000, 0 0 10px #000, 0 0 15px #000, 0 0 20px #000, 0 0 30px #000, 0 0 40px #000, 0 0 50px #000",
                    fontFamily: "ADLaM Display",
                    textAlign: "center",
                    fontSize: "1.5rem",
                    margin: "0 auto"
                }}>
                    Usted, como usuario administrador, tiene la posibilidad de añadir, editar, eliminar, etc. ¡Disfrute al máximo!
                </p>


                <div className="custom-expanded u-align-left u-video u-video-1">
                    <div className="embed-responsive embed-responsive-1">
                        <video
                            style={{
                                border:" 2px solid #ffffff",
                                borderRadius:"10px",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            }}
                            autoPlay={true}
                            loop
                            className="embed-responsive-item"
                            src={vaca}
                            frameBorder={0}

                        />
                    </div>
                </div>
            </div>
        </section>


    )
}
export default adminHome;