import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../../styles/PanelRecuperacion.css'; // Importa el archivo de estilos CSS

const PanelRecuperacion = () => {
    const navigation = useNavigate();
    const [selectedOption, setSelectedOption] = useState(null);

    const handleRecuperacion = (opcion) => {
        setSelectedOption(opcion);

        // Si el usuario elige "Pregunta Secreta", redirigir a la página correspondiente
        if (opcion === 'preguntaSecreta') {
            navigation('/PreguntaSecreta');
        } else if (opcion === 'correoElectronico') {
            // Aquí puedes agregar la lógica para enviar un correo electrónico de recuperación de contraseña
            navigation('/correo');
        }
    };

    return (
        <div className="panel-recuperacion">
            <h2>Recuperación de Contraseña</h2>
            <div className="opciones">
                <button onClick={() => handleRecuperacion('preguntaSecreta')} className={selectedOption === 'preguntaSecreta' ? 'selected' : ''}>Pregunta Secreta</button>
                <button onClick={() => handleRecuperacion('correoElectronico')} className={selectedOption === 'correoElectronico' ? 'selected' : ''}>Correo Electrónico</button>
            </div>
        </div>
    );
}

export default PanelRecuperacion;