import '../../styles/adminCss/adminAddUser.css'
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FcViewDetails } from "react-icons/fc";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


const noti = withReactContent(Swal);
const AddAdminUser = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null); // Estado para almacenar el usuario seleccionado para editar
    const [dispositivo, setDispositivo] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const agregarUsuario = () => {
        Swal.fire({
            width: '700px', // Cambia el ancho del cuadro de diálogo aquí
            heightAuto: false, // Desactiva la altura automática para ajustar la altura manualmente si es necesario
            height: 'auto',
            title: 'Agregar Usuario',
            html: `
            <label for="nombre"><br>Nombre:</label>
            <input id="nombre" class="swal-input swal2-input" placeholder="Nombre" /><br>
            <label for="apePa">Apellido Paterno:</label>
            <input id="apePa" class="swal-input swal2-input" placeholder="Apellido Paterno" /><br>
            <label for="apeMa">Apellido Materno:</label>
            <input id="apeMa" class="swal-input swal2-input" placeholder="Apellido Materno" /><br>
            <label for="user">Usuario:</label>
            <input id="user" class="swal-input swal2-input" placeholder="Usuario" /><br>
            <label for="email">Email:</label>
            <input id="email" class="swal-input swal2-input" placeholder="Email" /><br>
            <label for="pass">Contraseña:</label>
            <input id="pass" class="swal-input swal2-input" type="password" placeholder="Contraseña" /><br>                       
            <label for="permisos">Permisos:</label>
            <select id="permisos" class="swal-input swal2-input">
                <option value="admin">Admin</option>
                <option value="usuario">Usuario</option>
            </select>
            `,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                // Obtener los valores del formulario
                const nombre = Swal.getPopup().querySelector('#nombre').value;
                const apePa = Swal.getPopup().querySelector('#apePa').value;
                const apeMa = Swal.getPopup().querySelector('#apeMa').value;
                const user = Swal.getPopup().querySelector('#user').value;
                const email = Swal.getPopup().querySelector('#email').value;
                const pass = Swal.getPopup().querySelector('#pass').value;
                const permisos = Swal.getPopup().querySelector('#permisos').value;

                // Validar campos
                if (!nombre || !apePa || !apeMa || !user || !email || !pass) {
                    Swal.showValidationMessage('Por favor completa todos los campos');
                    return;
                }

                // Enviar los datos del nuevo usuario al servidor
                Axios.post('https://servermascoteh.onrender.com/Insert', {
                    nombre,
                    apePa,
                    apeMa,
                    user,
                    email,
                    pass,
                    permisos
                }).then(() => {
                    Swal.fire('Usuario agregado', '', 'success');
                    cargarUsuarios(); // Volver a cargar la lista de usuarios
                }).catch(error => {
                    console.error('Error al agregar usuario:', error);
                    Swal.fire('Error', 'No se pudo agregar el usuario', 'error');
                });
            }
        });
    }


    const editarUsuario = (usuario) => {
        // Guardar el usuario seleccionado para editar
        setUsuarioSeleccionado(usuario);
        // Mostrar el formulario de edición utilizando SweetAlert
        Swal.fire({
            title: 'Editar Usuario',
            width: '700px', // Cambia el ancho del cuadro de diálogo aquí
            heightAuto: false, // Desactiva la altura automática para ajustar la altura manualmente si es necesario
            height: 'auto',
            html: `
            <label for="nombre"><br>Nombre:</label>
            <input id="nombre" value="${usuario.nombre}" class="swal2-input" placeholder="Nombre"><br>
            <label for="apePa">Apellido Paterno:</label>
            <input id="apePa" value="${usuario.apePa}" class="swal2-input" placeholder="Apellido Paterno"><br>
            <label for="apeMa">Apellido Materno:</label>
            <input id="apeMa" value="${usuario.apeMa}" class="swal2-input" placeholder="Apellido Materno"><br>
            <label for="user">Usuario:</label>
            <input id="user" value="${usuario.user}" class="swal2-input" placeholder="Usuario"><br>
            <label for="email">Email:</label>
            <input id="email" value="${usuario.email}" class="swal2-input" placeholder="Email"><br>
            <label for="pass">Contraseña:</label>
            <input id="pass" readOnly value="${usuario.pass}" class="swal2-input" placeholder="Contraseña"><br>
            <label for="dispo">Dispositivo:</label>
            <input id="dispo" value="${usuario.Dispositivo.ID}" class="swal2-input" placeholder="Dispositivo"><br>
            <label for="permisos">Permisos:</label>
            <select id="permisos" class="swal2-input">
                <option value="admin" ${usuario.permisos === 'admin' ? 'selected' : ''}>Admin</option>
                <option value="usuario" ${usuario.permisos === 'usuario' ? 'selected' : ''}>Usuario</option>
            </select>
            `,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                // Obtener los valores actualizados del formulario
                const nombre = Swal.getPopup().querySelector('#nombre').value;
                const apePa = Swal.getPopup().querySelector('#apePa').value;
                const apeMa = Swal.getPopup().querySelector('#apeMa').value;
                const user = Swal.getPopup().querySelector('#user').value;
                const email = Swal.getPopup().querySelector('#email').value;
                const pass = Swal.getPopup().querySelector('#pass').value;
                const permisos = Swal.getPopup().querySelector('#permisos').value;
                const dispositivo = Swal.getPopup().querySelector('#dispo').value;
                // Realizar la actualización en la base de datos
                Axios.put(`https://servermascoteh.onrender.com/editar/${usuario._id}`, {
                    nombre,
                    apePa,
                    apeMa,
                    user,
                    email,
                    pass,
                    Dispositivo: {
                        ID: dispositivo
                    },
                    permisos

                }).then(() => {
                    Swal.fire('Usuario actualizado', '', 'success');
                    cargarUsuarios(); // Volver a cargar la lista de usuarios
                }).catch(error => {
                    console.error('Error al actualizar usuario:', error);
                    Swal.fire('Error', 'No se pudo actualizar el usuario', 'error');
                });
            }
        });
    }

    //Funcion para asignar 
    const asignar = (usuario, dispositivo) => {
        setUsuarioSeleccionado(usuario);

        // Obtener la lista de dispositivos disponibles
        Axios.get('https://servermascoteh.onrender.com/dispositivo')
            .then(response => {
                const NSerie = response.data;

                // Generar las opciones para el selector de dispositivos
                const options = NSerie.map(dispositivo => `<option value="${dispositivo.NSerie}">${dispositivo.NSerie}</option>`).join('');

                // Mostrar el formulario de asignación utilizando SweetAlert
                Swal.fire({
                    title: 'Asignar Dispositivo',
                    html: `
                        <select id="dispo" class="swal2-select">${options}</select>
                    `,
                    showCancelButton: true,
                    confirmButtonText: 'Guardar',
                    cancelButtonText: 'Cancelar',
                    preConfirm: () => {
                        // Obtener el valor del dispositivo seleccionado del formulario
                        const dispositivoId = Swal.getPopup().querySelector('#dispo').value;

                        // Realizar la asignación en la base de datos
                        Axios.put(`https://servermascoteh.onrender.com/editar/${usuario._id}`, {
                            Dispositivo: {
                                ID: dispositivoId
                            },
                        }).then(() => {
                            Swal.fire('Dispositivo Asignado', '', 'success');
                            cargarUsuarios(); // Volver a cargar la lista de usuarios
                        }).catch(error => {
                            console.error('Error al asignar dispositivo:', error);
                            Swal.fire('Error', 'No se pudo asignar el dispositivo', 'error');
                        });
                    }
                });
            })
            .catch(error => {
                console.error('Error al obtener dispositivos:', error);
                Swal.fire('Error', 'No se pudieron obtener los dispositivos disponibles', 'error');
            });
    }
    const showUser = (usuario) => {
        Swal.fire({
            width: '800px', // Aumentamos el ancho del Swal
            title: 'Detalles del Usuario',
            html: `
            <div>
            <h3><strong>Perfil</strong></h3>
                <p><strong>ID:</strong> ${usuario._id}</p>
                <p><strong>Nombre:</strong> ${usuario.nombre}</p>
                <p><strong>Apellido Paterno:</strong> ${usuario.apePa}</p>
                <p><strong>Apellido Materno:</strong> ${usuario.apeMa}</p>
                <p><strong>Usuario:</strong> ${usuario.user}</p>
                <p><strong>Contraseña:</strong> ${usuario.pass}</p>
                <p><strong>Email:</strong> ${usuario.email}</p>
                <p><strong>Pregunta Secreta:</strong> ${usuario.question}</p>
                <p><strong>Respuesta:</strong> ${usuario.answer}</p>

                <h3><strong>Direccion:</strong></h3>
              
                <p><strong>Estado Secreta:</strong> ${usuario.Direccion.Estado}</p>
                <p><strong>Calle:</strong> ${usuario.Direccion.Calle}</p>
                <p><strong>Codigo Postal:</strong> ${usuario.Direccion.Cp}</p>
                <p><strong>N_Casa:</strong> ${usuario.Direccion.N_Casa}</p>
                <p><strong>Referencias:</strong> ${usuario.Direccion.Referencias}</p>
                <p><strong>Permisos:</strong> ${usuario.permisos}</p>
                
            </div>
        `,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cerrar',
            cancelButtonColor: '#d33'
        });
    };
    // Función para manejar la eliminación de un usuario
    const deleteUser = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará al usuario permanentemente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                Axios.delete(`https://servermascoteh.onrender.com/delete/${id}`).then(() => {
                    cargarUsuarios();
                    Swal.fire(
                        'Usuario eliminado',
                        'El usuario ha sido eliminado correctamente.',
                        'success'
                    );
                }).catch(error => {
                    console.error('Error al eliminar usuario:', error);
                    Swal.fire(
                        'Error',
                        'No se pudo eliminar el usuario.',
                        'error'
                    );
                });
            }
        });
    }
    useEffect(() => {
        // Realizar la consulta para obtener todos los usuarios
        Axios.get('https://servermascoteh.onrender.com/usuarios')
            .then(response => {
                // Actualizar el estado con los usuarios obtenidos
                setUsuarios(response.data);
            })
            .catch(error => {
                console.error('Error al obtener usuarios:', error);
            });

        // Realizar la consulta para obtener datos del dispositivo
        Axios.get('https://servermascoteh.onrender.com/dispositivo')
            .then(response => {
                // Actualizar el estado con los datos del dispositivo obtenidos
                setDispositivo(response.data);
            })
            .catch(error => {
                console.error('Error al obtener datos del dispositivo:', error);
            });
    }, []);
    const cargarUsuarios = async () => {
        try {
            const response = await Axios.get('https://servermascoteh.onrender.com/usuarios');
            setUsuarios(response.data);
        } catch (error) {
            console.error('Error al cargar usuarios:', error);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUsers = usuarios.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    return (
        <section class="u-align-center u-clearfix u-white u-section-addP" id="sec-ad19">
            <div className="u-expanded-width u-table u-table-responsive u-table-1">

                <h2 style={{

                    color: "#fff",
                    textShadow: "0 0 10px #000, 0 0 20px #000, 0 0 30px #000, 0 0 40px #000, 0 0 50px #000, 0 0 60px #000, 0 0 70px #000, 0 0 80px #000",
                    fontFamily: "ADLaM Display",
                    textAlign: "center"
                }} class="">ADMINISTRACION DE USUARIOS </h2>
                <br />
                <button className="add-button" onClick={agregarUsuario}>Agregar Usuario</button>
                <br />
                <br />
                <div className='u-table-container'>
                    <table className="u-table-entity">
                        <thead className="u-palette-4-base u-table-header u-table-header-1">
                            <tr style={{ height: 21 }}>

                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Nombre
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Usuario
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Email
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Permisos
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Dispositivo
                                </th>
                                <th className="u-border-1 u-border-palette-4-base u-table-cell">
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody className="u-table-body" style={{ backgroundColor: 'white' }}>
                            {currentUsers.map(usuario => (
                                <tr key={usuario._id} style={{ height: 76 }}>

                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{usuario.nombre}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{usuario.user}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{usuario.email}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{usuario.permisos}</td>
                                    <td className="u-border-1 u-border-grey-30 u-table-cell">{usuario.Dispositivo ? usuario.Dispositivo.ID : ''}</td>

                                    <td style={{ display: "flex" }} className="u-border-1 u-border-grey-30 u-table-cell">
                                        <button type="button" className="edit-button" onClick={() => asignar(usuario)}>Asignar</button>
                                        <FcViewDetails onClick={() => showUser(usuario)} size={60} />
                                        <button onClick={() => editarUsuario(usuario)} title="filter" class="tooltip">
                                            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="25" width="25">
                                                {/*!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
                                                <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
                                            </svg>
                                            <span class="tooltiptext">editar</span>

                                        </button>
                                        <FontAwesomeIcon onClick={() => editarUsuario(usuario)} size='2x' icon="fa-regular fa-pen-to-square" />

                                        <button onClick={() => deleteUser(usuario._id)} class="tooltip">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" height="25" width="25">
                                                <path fill="#6361D9" d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z" clip-rule="evenodd" fill-rule="evenodd"></path>
                                            </svg>
                                            <span class="tooltiptext">remove</span>
                                        </button>


                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="pagination">
                    {Array.from({ length: Math.ceil(usuarios.length / itemsPerPage) }, (_, i) => (
                        <button key={i} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );



}
export default AddAdminUser