import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';


const Footer = () => {

    const navigation = useNavigate();
const handleNavigation = () => {
    navigation('/PregFrec'); // Redirige a la ruta '/preguntas-frecuentes'
  };
  const handleNavigationPoliticas = () => {
    navigation('/Politicas'); // Redirige a la ruta '/preguntas-frecuentes'
  };
    return (
        <footer className="u-clearfix u-footer u-grey-80" id="sec-ddea">
            <div className="u-clearfix u-sheet u-sheet-1">
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        <div className="u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-container-layout-1">
                                <span className="u-align-center u-border-2 u-border-palette-1-base u-icon u-icon-circle u-text-white u-icon-1">
                                    <svg
                                        className="u-svg-link"
                                        preserveAspectRatio="xMidYMin slice"
                                        viewBox="0 0 54.757 54.757"
                                        style={{}}
                                    >
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            xlinkHref="#svg-e269"
                                        />
                                    </svg>
                                    <svg
                                        className="u-svg-content"
                                        viewBox="0 0 54.757 54.757"
                                        x="0px"
                                        y="0px"
                                        id="svg-e269"
                                        style={{ enableBackground: "new 0 0 54.757 54.757" }}
                                    >
                                        <g>
                                            <path
                                                d="M27.557,12c-3.859,0-7,3.141-7,7s3.141,7,7,7s7-3.141,7-7S31.416,12,27.557,12z M27.557,24c-2.757,0-5-2.243-5-5
		s2.243-5,5-5s5,2.243,5,5S30.314,24,27.557,24z"
                                            />
                                            <path
                                                d="M40.94,5.617C37.318,1.995,32.502,0,27.38,0c-5.123,0-9.938,1.995-13.56,5.617c-6.703,6.702-7.536,19.312-1.804,26.952
		L27.38,54.757L42.721,32.6C48.476,24.929,47.643,12.319,40.94,5.617z M41.099,31.431L27.38,51.243L13.639,31.4
		C8.44,24.468,9.185,13.08,15.235,7.031C18.479,3.787,22.792,2,27.38,2s8.901,1.787,12.146,5.031
		C45.576,13.08,46.321,24.468,41.099,31.431z"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="u-align-center u-text u-text-default u-text-1">
                                    Entrega Segura{" "}
                                </h3>
                                <p className="u-align-center u-text u-text-2">
                                    Entregamos nuestros productos de forma segura y con total
                                    confianza del mundo{" "}
                                </p>
                            </div>
                        </div>
                        <div className="u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-container-layout-2">
                                <span className="u-align-center u-border-2 u-border-palette-1-base u-file-icon u-icon u-icon-circle u-icon-2">
                                    <svg
                                        className="u-svg-link"
                                        preserveAspectRatio="xMidYMin slice"
                                        viewBox="0 0 511.846 511.846"
                                        style={{}}
                                    >
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            xlinkHref="#svg-d735"
                                        />
                                    </svg>
                                    <svg
                                        className="u-svg-content"
                                        viewBox="0 0 511.846 511.846"
                                        id="svg-d735"
                                    >
                                        <g>
                                            <path d="m225.336 258.021-163.44-88.824c-7.27-3.949-15.855-3.786-22.969.443-7.114 4.228-11.359 11.693-11.359 19.969v206.052c0 8.337 4.502 16.076 11.75 20.197l163.443 92.917c3.603 2.048 7.543 3.071 11.484 3.071 4.016 0 8.031-1.063 11.682-3.188 7.231-4.207 11.548-11.714 11.548-20.081v-210.143c0-8.521-4.65-16.343-12.139-20.413z"></path>
                                            <path d="m454.621 115.6c-.011-8.7-4.817-16.591-12.54-20.594l-178.226-92.399c-6.748-3.5-14.791-3.474-21.514.065l-175.605 92.399c-7.646 4.025-12.402 11.892-12.413 20.533-.01 8.639 4.727 16.517 12.362 20.559l175.606 92.976c3.4 1.799 7.133 2.699 10.868 2.699 3.689 0 7.379-.878 10.745-2.635l178.23-92.974c7.714-4.024 12.499-11.929 12.487-20.629z"></path>
                                            <path d="m472.919 169.64c-7.11-4.229-15.699-4.396-22.966-.444l-163.445 88.825c-7.487 4.07-12.137 11.891-12.137 20.412v210.143c0 8.367 4.317 15.874 11.548 20.081 3.651 2.125 7.665 3.187 11.682 3.187 3.94 0 7.882-1.023 11.484-3.07l163.444-92.917c7.248-4.121 11.749-11.86 11.749-20.197v-206.051c0-8.276-4.245-15.741-11.359-19.969z"></path>
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="u-align-center u-text u-text-default u-text-3">
                                    Paquetes{" "}
                                </h3>
                                <p className="u-align-center u-text u-text-4">
                                    Pregunta por nuestros paquetes que ofrecemos!!
                                </p>
                            </div>
                        </div>
                        <div className="u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-container-layout-3">
                                <span className="u-align-center u-border-2 u-border-palette-1-base u-file-icon u-icon u-icon-circle u-icon-3">
                                    <svg
                                        className="u-svg-link"
                                        preserveAspectRatio="xMidYMin slice"
                                        viewBox="0 0 512 512"
                                        style={{}}
                                    >
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            xlinkHref="#svg-d056"
                                        />
                                    </svg>
                                    <svg
                                        className="u-svg-content"
                                        viewBox="0 0 512 512"
                                        x="0px"
                                        y="0px"
                                        id="svg-d056"
                                        style={{ enableBackground: "new 0 0 512 512" }}
                                    >
                                        <g>
                                            <g>
                                                <path d="M493.563,431.87l-58.716-125.913c-32.421,47.207-83.042,80.822-141.639,91.015l49.152,105.401    c6.284,13.487,25.732,12.587,30.793-1.341l25.193-69.204l5.192-2.421l69.205,25.193    C486.63,459.696,499.839,445.304,493.563,431.87z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M256.001,0C154.815,0,72.485,82.325,72.485,183.516s82.331,183.516,183.516,183.516    c101.186,0,183.516-82.325,183.516-183.516S357.188,0,256.001,0z M345.295,170.032l-32.541,31.722l7.69,44.804    c2.351,13.679-12.062,23.956-24.211,17.585l-40.231-21.148l-40.231,21.147c-12.219,6.416-26.549-3.982-24.211-17.585l7.69-44.804    l-32.541-31.722c-9.89-9.642-4.401-26.473,9.245-28.456l44.977-6.533l20.116-40.753c6.087-12.376,23.819-12.387,29.913,0    l20.116,40.753l44.977,6.533C349.697,143.557,355.185,160.389,345.295,170.032z"></path>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M77.156,305.957L18.44,431.87c-6.305,13.497,7.023,27.81,20.821,22.727l69.204-25.193l5.192,2.421l25.193,69.205    c5.051,13.899,24.496,14.857,30.793,1.342l49.152-105.401C160.198,386.779,109.578,353.165,77.156,305.957z"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <h3 className="u-align-center u-text u-text-default u-text-5">
                                    Calidad{" "}
                                </h3>
                                <p className="u-align-center u-text u-text-6">
                                    Nuestros productos cuentan con pruebas de calidad para que los
                                    disfrutes con total seguridad{" "}
                                </p>
                            </div>
                        </div>
                        <div style={{ textAlign: "start" }} className="">
                            <hr></hr>
                            <ul onClick={handleNavigationPoliticas} >Politicas</ul>
                            <hr></hr>
                            <ul onClick={ handleNavigation}>Preguntas Frecuentes</ul>

                        </div>
                    </div>
                </div>



            </div>
        </footer>


    );
};

export default Footer;