import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/detalles.css';
import 'tailwindcss/tailwind.css';
import '../styles/productos.css';


export const Productos = () => {

    const [productos, setProductos] = useState([]);
    const [tipoSeleccionado, setTipoSeleccionado] = useState('');

    /* PAGINACION */
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProducts = productos.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);



    useEffect(() => {
        // Realizar la consulta para obtener todos los productos
        if (!tipoSeleccionado) {
            Axios.get('https://servermascoteh.onrender.com/productos')
                .then(response => {
                    // Actualizar el estado con los productos obtenidos
                    setProductos(response.data);
                    console.log(response.data);
                })
                .catch(error => {
                    console.error('Error al obtener todos los productos:', error);
                });
        } else {
            // Realizar la consulta para obtener los productos según el tipo seleccionado
            Axios.get(`https://servermascoteh.onrender.com/productos/${tipoSeleccionado}`)
                .then(response => {
                    // Actualizar el estado con los productos obtenidos
                    setProductos(response.data);
                })
                .catch(error => {
                    console.error('Error al obtener productos por tipo:', error);
                });
        }
    }, [tipoSeleccionado]);

    const handleTipoChange = (tipo) => {
        setTipoSeleccionado(tipo);
    };
    return (
        <div className='container-allProducts'>
            <section className="u-align-center u-clearfix u-container-align-center u-section-products" id="sec-ff98">
                <h2 className="lobster-font">PRODUCTOS</h2>


                <div className='shop-container' style={{ display: "flex" }}>

                    <div className="categorias">
                        <h3>Categorías</h3>
                        <ul>
                            <li><a onClick={() => handleTipoChange('')}>Todo</a></li>
                            <li><a onClick={() => handleTipoChange('dispensador')}>Dispensadores</a></li>
                            <li><a onClick={() => handleTipoChange('cepillo')}>Cepillos</a></li>
                            <li><a onClick={() => handleTipoChange('higiene')}>Higiene</a></li>
                            <li><a onClick={() => handleTipoChange('alimento')}>Alimentos</a></li>
                            <li><a onClick={() => handleTipoChange('cama')}>Camas</a></li>
                            <li><a onClick={() => handleTipoChange('accesorio')}>Accesorios</a></li>
                            <li><a onClick={() => handleTipoChange('juguete')}>Juguetes</a></li>
                            {/* Resto de las categorías */}
                        </ul>
                    </div>

                    <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                        <div className="u-expanded-width u-products u-products-1"
                            data-site-sorting-prop="created"
                            data-site-sorting-order="desc"
                            data-items-per-page={6}
                            data-products-datasource="site"
                            data-products-id={1}
                        >
                            <div className="u-repeater u-repeater-1">
                                {/* Renderizar productos actuales */}
                                {currentProducts.map(producto => (
                                    <div key={producto.id} className="u-align-center u-container-align-center u-container-style u-products-item u-repeater-item u-repeater-item-1"
                                        data-product-id={producto.id}
                                    >
                                        <div className="card">
                                            <svg width="300px" height="300" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                {/* Aquí especifica la ruta de la imagen y su posición */}
                                                <image href={producto.imagen} x="0" y="0" width="100px" height="100px" />
                                            </svg>
                                            <div className="card__content">
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <p style={{ textTransform: "uppercase", textAlign: 'center', fontSize: "24px" }} className="card__title">{producto.nombre}</p>
                                                <p style={{ color: "green", textAlign: 'center' }} className="card__description">
                                                    ${producto.precio}
                                                </p>
                                                <br></br>
                                                <Link
                                                    to={`/Detalles/${producto._id}`}
                                                    className="card__button"
                                                    style={{
                                                        color: "#000", // Color inicial del enlace
                                                        transition: "color 0.3s ease", // Agrega una transición suave al cambio de color
                                                    }}
                                                    onMouseOver={(e) => e.target.style.color = "white"} // Cambia el color al pasar el cursor sobre el enlace
                                                    onMouseOut={(e) => e.target.style.color = "white"}   // Restaura el color original cuando el cursor sale del enlace
                                                >Ver Mas</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/* Si no hay productos, mostrar una animación o mensaje de carga */}
                                {currentProducts.length === 0 && (

                                    <div
                                        aria-label="Orange and tan hamster running in a metal wheel"
                                        role="img"
                                        className="wheel-and-hamster"
                                    >
                                        <div className="wheel" />
                                        <div className="hamster">
                                            <div className="hamster__body">
                                                <div className="hamster__head">
                                                    <div className="hamster__ear" />
                                                    <div className="hamster__eye" />
                                                    <div className="hamster__nose" />
                                                </div>
                                                <div className="hamster__limb hamster__limb--fr" />
                                                <div className="hamster__limb hamster__limb--fl" />
                                                <div className="hamster__limb hamster__limb--br" />
                                                <div className="hamster__limb hamster__limb--bl" />
                                                <div className="hamster__tail" />
                                            </div>
                                        </div>
                                        <div className="spoke" />
                                    </div>
                                )}
                            </div>
                            {/* Controles de paginación */}
                            <div>
                                {productos.length > itemsPerPage && (
                                    <ul className="pagination">
                                        {Array.from({ length: Math.ceil(productos.length / itemsPerPage) }, (_, i) => (
                                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                <a onClick={() => paginate(i + 1)} className="page-link">
                                                    {i + 1}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Productos;