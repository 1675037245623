import React from 'react';
import './App.css';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import InicioSesion from './components/InicioSesion.js';
import Registro from './components/Registro.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Home from './components/Home.js';
import Productos from './components/Productos.js'
import DetalleProductos from './components/DetalleProducto'
import AppControl from './components/AppControl'
import QuienesSomos from './components/QuienesSomos.js'
import Contacto from './components/Contacto.js'
import Perfil from './components/Perfil.js';
import PreguntasFrecuentes from './components/PreguntasFrecuentes.js';

import Panel from './components/Recuperacion/Panel.js'
import PregSec from './components/Recuperacion/PreguntaSecreta.js'
import PregSecForm from './components/Recuperacion/PreguntaSecretaForm.js'
import ResetPassword from './components/Recuperacion/ResetPassword.js';
import ValidateCode from './components/Recuperacion/ValidateCode.js';
import Correo from './components/Recuperacion/correo.js';
import Politicas from './components/Politicas.js';

import AddUser from './components/AdminComponents/AdminAddUser.js'
import AdminHome from './components/AdminComponents/AdminHome.js'
import AdminProduct from './components/AdminComponents/AdminAddProducts.js'
import AdminQn from './components/AdminComponents/AdminQuienesSomos.js'
import AdminContac from './components/AdminComponents/AdminContacto.js'
import AdminPoli from './components/AdminComponents/AdminPoliticas.js'
import AdminComentarios from './components/AdminComponents/AdminComentarios.js'
import AdminTeam from './components/AdminComponents/AdminTeamWork.js'
import AdminVision from './components/AdminComponents/adminVision.js'
import AdminMision from './components/AdminComponents/AdminMision.js'
import AdminPregFrec from './components/AdminComponents/AdminPregFrec.js'

const App = () => {
  return (
    <div className="App">
      <Router>
        <div className="App">
          <Header />
          <Routes>  
            {/* Ruta por defecto */}
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/InicioSesion" element={<InicioSesion />} />
            <Route path="/Registro" element={<Registro />} />
            <Route path="/Productos" element={<Productos/>} />
            <Route path="/Detalles/:id" element={<DetalleProductos/>} />
            <Route path="/AppControl" element={<AppControl/>} />
            <Route path="/QuienesSomos" element={<QuienesSomos/>} />
            <Route path="/Contacto" element={<Contacto/>} />
            <Route path="/Perfil" element={<Perfil/>} />
            <Route path="/PregFrec" element={<PreguntasFrecuentes/>} />
            <Route path="/Politicas" element={<Politicas/>} />-

            <Route path="/Panel" element={<Panel/>} />
            <Route path="/PreguntaSecreta" element={<PregSec/>} />
            <Route path="/PreguntaSecretaForm" element={<PregSecForm/>} />
            <Route path="/ResetPass" element={<ResetPassword/>} />
            <Route path="/ValidateCode" element={<ValidateCode/>} />
            <Route path="/Correo" element={<Correo/>} />

            <Route path="/adminHome" element={<AdminHome />} />
            <Route path="/adminAddUser" element={<AddUser />} />
            <Route path="/adminProduct" element={<AdminProduct />} />
            <Route path="/adminQn" element={<AdminQn />} />
            <Route path="/adminContac" element={<AdminContac />} />
            <Route path="/adminPoli" element={<AdminPoli />} />
            <Route path="/adminComentarios" element={<AdminComentarios />} />
            <Route path="/adminTeam" element={<AdminTeam />} />
            <Route path="/adminVision" element={<AdminVision />} />
            <Route path="/adminMision" element={<AdminMision />} />
            <Route path="/adminPregFrec" element={<AdminPregFrec />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </div>
  );
};
export default App;