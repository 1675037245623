import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import '../styles/pregfrec.css';

const PreguntasFrecuentes = () => {
    const [preguntas, setPreguntas] = useState([]);
    const [preguntaAbierta, setPreguntaAbierta] = useState(null);

    useEffect(() => {
        // Realizar la consulta para obtener las preguntas frecuentes
        Axios.get('https://servermascoteh.onrender.com/getPreguntas')
            .then(response => {
                setPreguntas(response.data);
            })
            .catch(error => {
                console.error('Error al obtener preguntas frecuentes:', error);
            });
    }, []);

    const toggleRespuesta = (id) => {
        // Solo abrir la pregunta clicada y cerrar las demás
        setPreguntaAbierta(preguntaAbierta === id ? null : id);
    };

    return (
        <div className='container-all'>
            <section className="faq-container">
                <div className="faq-header">
                    <div className="faq-title">Preguntas & Respuestas</div>
                    <div className="faq-subtitle">
                        <h2>¿Tienes alguna pregunta?</h2>
                        <p>Nosotros tenemos las respuestas. Echa un vistazo a nuestras preguntas frecuentes para encontrar lo que buscas.</p>
                    </div>
                </div>
                <div className="faq-list">
                    {preguntas.map((pregunta) => (
                        <div key={pregunta.id} className="faq-item">
                            <div
                                onClick={() => toggleRespuesta(pregunta._id)}
                                className="faq-question"
                            >
                                {pregunta.pregunta}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={`faq-icon ${preguntaAbierta === pregunta._id ? 'rotate-180' : ''
                                        }`}
                                >
                                    <path d="m6 9 6 6 6-6" />
                                </svg>
                            </div>
                            {preguntaAbierta === pregunta._id && (
                                <div className="faq-answer">
                                    <p>{pregunta.respuesta}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default PreguntasFrecuentes;
