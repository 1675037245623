import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import '../styles/detalles.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CardGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { MdArrowBackIosNew } from "react-icons/md";
const DetalleProducto = () => {
    const { id } = useParams();
    const [producto, setProducto] = useState(null);

    const msj = () => {
        return (
            Swal.fire({
                icon: "error",
                title: 'No disponible',
                text: 'Mantenimiento....',
            })
        )
    }

    useEffect(() => {
        console.log(id);
        Axios.get(`https://servermascoteh.onrender.com/getDetails/${id}`)
            .then(response => {
                setProducto(response.data);
            })
            .catch(error => {
                console.error('Error al obtener detalles del producto:', error);
            });
    }, [id]);

    return (
        <section style={{ backgroundColor: "#ededed" }} className='cuerpaso'>
            <div>
                <section style={{ backgroundColor: "white", padding: "20px" }} className="product-details">

                    {producto ? (
                        <>
                            <div>
                                <div style={{display:"flex"}}> <Link to="/Productos"> <MdArrowBackIosNew  size={40} style={{ marginBottom: "10px", }} /></Link></div>

                                <div className="product-info">

                                    <h2 style={{ textTransform: "uppercase" }} className="product-title">{producto.nombre}</h2>
                                    <p className="product-price">${producto.precio}</p>
                                    <p className="product-description">{producto.descripcion}</p>
                                    <p className="product-marca">{producto.marca}</p>


                                </div>
                            </div>

                            <div style={{ width: "500px", alignItems: "center" }}>
                                <div className="product-images">
                                    <img width={500} height={500} src={producto.imagen} alt={producto.nombre} />
                                </div>
                            </div>


                            <Card style={{ display: "block", transition: "none", transform: "none", background: "none", marginTop: "30px", padding: "20px", width: "200%", minHeight: "auto", overflowY: "auto", WebkitOverflowScrolling: "touch" }}>

                                <Card.Body >
                                    <Card.Title style={{ textTransform: "uppercase", fontSize: "30px", textAlign: "start" }}>{producto.nombre}</Card.Title>
                                    <br></br>
                                    <Card.Title style={{ textAlign: "start" }}>Descripcion</Card.Title>
                                    <Card.Text  >
                                        {producto.descripcion_detallada}
                                    </Card.Text>
                                    <Card.Title style={{ textAlign: "start" }}>Caracteristicas</Card.Title>
                                    <Card.Text  >
                                        {producto.caracteristicas}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    ) : (
                        <div
                            aria-label="Orange and tan hamster running in a metal wheel"
                            role="img"
                            className="wheel-and-hamster"
                        >
                            <div className="wheel" />
                            <div className="hamster">
                                <div className="hamster__body">
                                    <div className="hamster__head">
                                        <div className="hamster__ear" />
                                        <div className="hamster__eye" />
                                        <div className="hamster__nose" />
                                    </div>
                                    <div className="hamster__limb hamster__limb--fr" />
                                    <div className="hamster__limb hamster__limb--fl" />
                                    <div className="hamster__limb hamster__limb--br" />
                                    <div className="hamster__limb hamster__limb--bl" />
                                    <div className="hamster__tail" />
                                </div>
                            </div>
                            <div className="spoke" />
                        </div>
                    )}
                </section>
            </div >
        </section >
    );
}

export default DetalleProducto;
