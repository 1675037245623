import '../../styles/preguntaSecreta.css'
import React, { useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import md5 from 'md5';

const ResetPassword = () => {
    const navigation = useNavigate();
    // Estados para almacenar el correo electrónico y la contraseña
    const [pass, setPass] = useState("");
    const [repass, setRepass] = useState("");
    const hashedPassword = md5(pass);
    // Función para manejar el envío del formulario
    const handleSubmit = () => {
        if (pass !== repass) {
            Swal.fire({
                icon: 'error',
                title: 'Error de registro',
                text: 'Las contraseñas no coinciden.',
                timer: 3000
            });
        }
        else {
            Swal.fire({
                icon: "info",
                title: `Verificando...`,
                html: "Espere Por favor",
                allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
                showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él

            })

            Axios.put(`https://servermascoteh.onrender.com/resetPass/${localStorage.getItem('passId')}`, {
                
                pass: hashedPassword

            }).then(response => {
                // Verificar si la respuesta del servidor tiene un estado definido y es 200
                if (response && response.status === 200) {

                    // Aquí puedes verificar el tipo de usuario

                    Swal.fire({
                        icon: "success",
                        title: `Verificacion correcta`,
                        timer: 2000
                    }).then(() => {
                        navigation("/Home");
                    });
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: `Credenciales Incorrectas`,
                        html: "No se ha encontrado el usuario",
                        allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
                        showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él

                    })
                }
            }).catch(error => {
                // Manejar errores de la solicitud
                if (error.response && error.response.status === 404) {
                    Swal.fire({
                        icon: "warning",
                        title: `Datos Incorrectos`,
                        html: "Verifique su respuesta",


                    })
                } else {
                    // Manejar otros errores
                    console.error("Error:", error);
                    alert(error);
                }
            });
        }
    }

    return (
        <div className="container">
            <form className="form-control" action="">
                <p className="title">RECUPERACION</p>
                <div className="input-field">


                    <div class="input-field">
                        <input value={pass}
                            onChange={(event) => {
                                setPass(event.target.value);

                            }} required="" class="input" type="password" />
                        <label class="label" for="input">Nueva Contraseña</label>
                        <input value={repass}
                            onChange={(event) => {
                                setRepass(event.target.value);

                            }} required="" class="input" type="password" />
                        <label class="label" for="input">Confirme su nueva contraseña</label>
                    </div>
                </div>

                <button onClick={handleSubmit} className="submit-btn">Cambiar Contraseña</button>
            </form>
        </div>
    );

};

export default ResetPassword;