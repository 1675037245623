import '../../styles/preguntaSecreta.css'
import React, { useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const RecoverPassQuestion = () => {
    const navigation = useNavigate();
    // Estados para almacenar el correo electrónico y la contraseña
    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState(false); // Estado para indicar si hay un error en el campo

    // Función para manejar el envío del formulario
    const handleSubmit = () => {
        if (!username.trim()) {
            setUsernameError(true); // Establecer el estado de error como verdadero si el campo está vacío
            return;
        }
        Swal.fire({
            icon: "info",
            title: `Verificando...`,
            html: "Espere Por favor",
            allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
            showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él

        })

        Axios.post("https://servermascoteh.onrender.com/validateUser", {
            username: username,
        }).then(response => {
            // Verificar si la respuesta del servidor tiene un estado definido y es 200
            if (response && response.status === 200) {

                // Aquí puedes verificar el tipo de usuario

                Swal.fire({
                    icon: "success",
                    title: `Verificacion correcta`,
                    timer: 2000
                }).then(() => {
                    localStorage.setItem('userQuestion', response.data.question);
                    console.log(localStorage.getItem('userAnswer'));
                    navigation("/PreguntaSecretaForm");
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    title: `Credenciales Incorrectas`,
                    html: "No se ha encontrado el usuario",
                    allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del modal
                    showConfirmButton: false // Oculta el botón de confirmación para evitar que se cierre haciendo clic en él

                })
            }
        }).catch(error => {
            // Manejar errores de la solicitud
            if (error.response && error.response.status === 404) {
                Swal.fire({
                    icon: "warning",
                    title: `Credenciales Incorrectas`,
                    html: "No se ha encontrado el usuario",


                })
            } else {
                // Manejar otros errores
                console.error("Error:", error);
                alert(error);
            }
        });
    }

    return (
        <div className="container">
            <form className="form-control" action="">
                <p className="title">Recuperación</p>
                <div className="input-field">
                    <input 
                        value={username}
                        onChange={(event) => {
                            setUsername(event.target.value);
                            setUsernameError(false); // Establecer el estado de error como falso cuando se escriba en el campo
                        }}
                        required=""
                        className={`input ${usernameError ? 'error' : ''}`} // Agregar una clase de error si hay un error en el campo
                        type="text" 
                    />
                    <label className="label" htmlFor="input">Usuario</label>
                    {usernameError && <p className="error-message">Por favor, ingrese su nombre de usuario.</p>} {/* Mostrar el mensaje de error si hay un error en el campo */}
                </div>

                <button onClick={handleSubmit} className="submit-btn">Validar Usuario</button>
            </form>
        </div>
    );
};
export default RecoverPassQuestion;